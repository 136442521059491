import { ChangeEvent, useEffect, useState } from 'react';
import { numberWithSeparators } from '../../../../library/functions';
import courses, { CourseType } from '../../../../data/course/courseData';
import prices from '../../../../data/price/priceData';
import programs, { ProgramType } from '../../../../data/program/programData';
import years from '../../../../data/year/yearData';
import s from './ProgramList.module.css';
import { Helmet } from 'react-helmet';

const ProgramList: React.FC<{}> = () => {
	const [currentCourseId, setCurrentCourseId] = useState<number>(0);
	const [currentCourseYear, setCurrentCourseYear] = useState<number>(1);

	const currentCourseHandler = (event: ChangeEvent<HTMLSelectElement>) => {
		setCurrentCourseId(+event.target.value);
	}

	const currentCourseYearHandler = (event: ChangeEvent<HTMLSelectElement>) => {
		setCurrentCourseYear(+event.target.value);
	}

	const [currentProgramList, setCurrentProgramList] = useState<ProgramType[]>([]);

	useEffect(() => {
		let selectedCourse: CourseType[] = courses.filter((el) => el.id === currentCourseId);
		if (selectedCourse.length > 0) {
			const programsInCourse = programs.filter((el) =>
				selectedCourse[0].programs.includes(el.id)
				&& el.years.includes(+currentCourseYear)
			);
			setCurrentProgramList(programsInCourse);
		} else {
			setCurrentProgramList([]);
		}
	}, [currentCourseId, currentCourseYear]);

	let coursesFilteredByYear: CourseType[] = courses
		.filter((el) => el.years.includes(+currentCourseYear))
		.sort((a, b) => a.code.localeCompare(b.code));

	return (
		<div className={s.programList}>
			<Helmet>
				<title>Перечень программ | TheWiz — образовательная онлайн-платформа</title>
				<meta name="description" content="Перечень всех действующих программ Английской школы и их стоимость при обучении в различных форматах" />
			</Helmet>
			<h2>Перечень программ</h2>
			<div className={s.programList__selectors}>
				<div className={s.programList__selector}>
					<div>Год:</div>
					<select value={currentCourseYear} onChange={currentCourseYearHandler}>
						{years.slice().reverse().map((el) => {
							return (<option value={el.id}>{el.name}</option>);
						})}
					</select>
				</div>
				<div className={s.programList__selector}>
					<div>Курс:</div>
					<select value={currentCourseId} onChange={currentCourseHandler}>
						<option value={0}>Выберите курс...</option>
						{coursesFilteredByYear.map((el) => {
							return (<option value={el.id}>{el.code} — {el.alias}</option>);
						})}
					</select>
				</div>
			</div>
			{currentCourseId !== 0 && <div className={s.programList__tableContainer}>
				{Object.keys(currentProgramList).length !== 0 &&
					<table className={s.programList__listTable}>
						<tr>
							<th rowSpan={3}>Код курса и&nbsp;ОП</th>
							<th rowSpan={3}>Наименование курса и&nbsp;входящих в&nbsp;него образовательных программ (ОП)
							</th>
							<th rowSpan={3}>Базовый учебно-методический комплекс</th>
							<th rowSpan={3}>Объём ОП, астр. ч</th>
							<th rowSpan={3}>Максимальный срок освоения ОП</th>
							<th colSpan={4}>Полная стоимость ОП (и&nbsp;схема оплаты в&nbsp;рассрочку), рублей (НДС не&nbsp;облагается)</th>
						</tr>
						<tr>
							<th colSpan={3}>В группе</th>
							<th rowSpan={2}>Индивиду-ально</th>
						</tr>
						<tr>
							<th>4–12<br />учеников</th>
							<th>3<br />ученика</th>
							<th>2<br />ученика</th>
						</tr>
						<tr>
							<td colSpan={1} className={s.programList__cellCourseTitle + ' ' + s.programList__fontMedium + ' ' + s.programList__textAlignCenter}>{courses.filter((el) => el.id === currentCourseId)[0].code}</td>
							<td colSpan={8} className={s.programList__cellCourseTitle + ' ' + s.programList__fontMedium}>Курс &laquo;{courses.filter((el) => el.id === currentCourseId)[0].name}&raquo;</td>
						</tr>
						{currentProgramList.map((el) => {
							const priceGroup4to12 = prices.filter((priceEl) => priceEl.id === el.priceIdGroup4to12)[0].price;
							const priceGroup3 = prices.filter((priceEl) => priceEl.id === el.priceIdGroup3)[0].price;
							const priceGroup2 = prices.filter((priceEl) => priceEl.id === el.priceIdGroup2)[0].price;
							const priceIndividual = prices.filter((priceEl) => priceEl.id === el.priceIdIndividual)[0].price;

							return <tr>
								<td className={s.programList__textAlignCenter}>{el.code}</td>
								<td>{el.name}</td>
								<td className={s.programList__textAlignCenter}>{el.base}</td>
								<td className={s.programList__textAlignCenter}>{el.hours}</td>
								<td className={s.programList__textAlignCenter}>{el.maxLengthMonths > 1 && (el.maxLengthMonths + " мес. / ")}{el.maxLengthWeeks} нед.</td>
								<td className={s.programList__textAlignCenter}>{priceGroup4to12 !== 0 ? <div><span className={s.programList__fontMedium}>{numberWithSeparators(priceGroup4to12)}</span>
									{el.maxLengthMonths > 1 && (<div>({numberWithSeparators(priceGroup4to12 / el.maxLengthMonths)} x {el.maxLengthMonths})</div>)} </div> : "—"}</td>
								<td className={s.programList__textAlignCenter}>{priceGroup3 !== 0 ? <div><span className={s.programList__fontMedium}>{numberWithSeparators(priceGroup3)}</span>
									{el.maxLengthMonths > 1 && (<div>({numberWithSeparators(priceGroup3 / el.maxLengthMonths)} x {el.maxLengthMonths})</div>)}
								</div> : "—"}</td>
								<td className={s.programList__textAlignCenter}>{priceGroup2 !== 0 ? <div><span className={s.programList__fontMedium}>{numberWithSeparators(priceGroup2)}</span>
									{el.maxLengthMonths > 1 && (<div>({numberWithSeparators(priceGroup2 / el.maxLengthMonths)} x {el.maxLengthMonths})</div>)}
								</div> : "—"}</td>
								<td className={s.programList__textAlignCenter}>{priceIndividual !== 0 ? <div><span className={s.programList__fontMedium}>{numberWithSeparators(priceIndividual)}</span>
									{el.maxLengthMonths > 1 && (<div>({numberWithSeparators(priceIndividual / el.maxLengthMonths)} x {el.maxLengthMonths})</div>)}
								</div> : "—"}</td>
							</tr>
						})
						}
					</table>
				}
			</div>}
		</div >
	);
}

export default ProgramList;