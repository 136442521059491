import s from './Breadcrumbs.module.css';

const Breadcrumbs: React.FC<{}> = () => {
	switch (window.location.pathname) {
		case "/play":
		case "/crm":
			return (
				<div className={s.breadcrumbs}>
					<a href="/">Главная</a>
				</div>
			);
		case '/play/math':
		case '/play/english':
			return (
				<div className={s.breadcrumbs}>
					<a href="/">Главная</a>&nbsp;&gt; <a href="/play">Playground</a>
				</div>
			);
		case '/play/math/multiplication-table':
		case '/play/math/addition-in-mind':
		case '/play/math/subtraction-in-mind':
		case '/play/math/division-in-mind':
			return (
				<div className={s.breadcrumbs}>
					<a href="/">Главная</a>&nbsp;&gt; <a href="/play">Playground</a>&nbsp;&gt; <a href="/play/math">Математика</a>
				</div>
			);
		case '/play/english/object-pronouns':
			return (
				<div className={s.breadcrumbs}>
					<a href="/">Главная</a>&nbsp;&gt; <a href="/play">Playground</a>&nbsp;&gt; <a href="/play/english">English</a>
				</div>
			);
		case '/crm/price':
		case '/crm/program-list':
			return (
				<div className={s.breadcrumbs}>
					<a href="/">Главная</a>&nbsp;&gt; <a href="/crm">CRM</a>
				</div>
			);
	}
	return (null);
}

export default Breadcrumbs;