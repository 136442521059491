
import Play from './Play/Play';
import s from './Main.module.css';
import { Route, Routes } from 'react-router-dom';
import Crm from './Crm/Crm';
import { Helmet } from 'react-helmet';
import NotFound from '../Core/NotFound/NotFound';

const MainContent: React.FC<{}> = () => {
	return (
		<div className={s.main__content}>
			<div className={s.main__contentHero}>
				<h2>Присоединяйся!</h2>
				<div>Смотри, что у&nbsp;нас есть сегодня</div>
			</div>
			<div className={s.main__contentSections}>
				<div className={s.main__contentSection}>
					<h3>Playground</h3>
					<div>Интерактивные тренажеры</div>
					<a href="/play">Перейти</a>
				</div>
			</div>
		</div>
	);
}

const Main: React.FC<{}> = () => {
	return (
		<div className={s.main}>
			<Helmet>
				<title>TheWiz — образовательная онлайн-платформа</title>
				<meta name="description" content="TheWiz — образовательная онлайн-платформа" />
			</Helmet>
			<Routes>
				<Route path="/" Component={MainContent} />
				<Route path="/play/*" Component={Play} />
				<Route path="/crm/*" Component={Crm} />
				<Route path="*" Component={NotFound} />
			</Routes>
		</div>
	);
}

export default Main;