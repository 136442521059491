export type CourseType = {
	id: number,
	code: string,
	name: string,
	alias: string,
	programs: number[],
	years: number[]
}

const courses: CourseType[] = [
	{
		id: 1,
		code: "EF1",
		name: "Английский для детей 7–11 лет (1–5 классы)",
		alias: "Английский для детей",
		programs: [1, 2, 3, 4, 5],
		years: [0]
	},
	{
		id: 2,
		code: "EF2",
		name: "Английский для подростков 12–18 лет (6–10 классы)",
		alias: "Английский для подростков",
		programs: [6, 7, 8, 9, 10, 11, 44],
		years: [0]
	},
	{
		id: 3,
		code: "EF3",
		name: "Общий английский для взрослых",
		"alias": "Общий английский для взрослых",
		programs: [12, 13, 14, 15, 16, 17],
		years: [0, 1]
	},
	{
		id: 4,
		code: "EF4",
		name: "Деловой английский для взрослых",
		alias: "Деловой английский для взрослых",
		programs: [18, 19, 20, 21, 22, 23],
		years: [0, 1]
	},
	{
		id: 5,
		code: "EF5",
		name: "Подготовка к экзаменам по английскому языку",
		alias: "Экзамены по английскому",
		programs: [24, 25, 26],
		years: [0, 1]
	},
	{
		id: 6,
		code: "A",
		name: "Частная школа с углубленным изучением математики, русского и английского языков",
		alias: "Частная школа",
		programs: [27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38],
		years: [0]
	},
	{
		id: 7,
		code: "K",
		name: "Школа для дошкольников",
		alias: "Школа для дошкольников",
		programs: [39, 40, 41],
		years: [0]
	},
	{
		id: 8,
		code: "C",
		name: "Летний городской лагерь",
		alias: "Летний лагерь",
		programs: [42, 43],
		years: [0]
	},
	{
		id: 9,
		code: "EF1",
		name: "Английский для детей 7–11 лет (1–5 классы)",
		alias: "Английский для детей",
		programs: [45, 46, 47, 48, 49],
		years: [1]
	},
	{
		id: 10,
		code: "EF2",
		name: "Английский для подростков 12–18 лет (6–10 классы)",
		alias: "Английский для подростков",
		programs: [50, 51, 52, 53, 54, 55, 56],
		years: [1]
	},
	{
		id: 11,
		code: "A",
		name: "Частная школа с углубленным изучением математики, русского и английского языков",
		alias: "Частная школа",
		programs: [57, 67, 58, 68, 59, 69, 60, 70, 61, 71, 62, 72, 63, 73],
		years: [1]
	},
	{
		id: 12,
		code: "K",
		name: "Школа для дошкольников",
		alias: "Школа для дошкольников",
		programs: [64, 65, 66],
		years: [1]
	},
	{
		id: 13,
		code: "C",
		name: "Летний городской лагерь",
		alias: "Летний лагерь",
		programs: [74, 75, 76, 77],
		years: [1]
	}
]

export default courses;