export type YearDataType = {
	id: number,
	name: string,
}

const years: YearDataType[] = [
	{
		id: 0,
		name: "2023/24 (архив)",
	},
	{
		id: 1,
		name: "2024/25",
	}
];

export default years;