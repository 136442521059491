export type ProgramType = {
	id: number,
	code: string,
	name: string,
	alias: string,
	class?: string,
	classRange?: string,
	base: string,
	hours: number,
	maxLengthWeeks: number,
	maxLengthMonths: number,
	priceIdGroup4to12: number,
	priceIdGroup4to12Education?: number,
	priceIdGroup4to12Care?: number,
	priceIdGroup3: number,
	priceIdGroup2: number,
	priceIdIndividual: number,
	priceValidFrom: string,
	earlyPaymentPossible: boolean,
	years: number[]
}

const programs: ProgramType[] = [
	{
		id: 1,
		code: "EF1.1",
		name: "Английский для детей. Часть 1 из 5 — модуль Старт",
		alias: "Kids 1",
		class: "1",
		classRange: "1–2",
		base: "English Formula Kids 1",
		hours: 64,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 1,
		priceIdGroup3: 37,
		priceIdGroup2: 35,
		priceIdIndividual: 36,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 2,
		code: "EF1.2",
		name: "Английский для детей. Часть 2 из 5 — модуль Pre-A1 с подготовкой к пробному экзамену Pre-A1",
		alias: "Kids 2",
		class: "2",
		classRange: "1–3",
		base: "English Formula Kids 2",
		hours: 96,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 2,
		priceIdGroup3: 3,
		priceIdGroup2: 4,
		priceIdIndividual: 5,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 3,
		code: "EF1.3",
		name: "Английский для детей. Часть 3 из 5 — модуль Pre-A1 с подготовкой к экзамену Pre-A1",
		alias: "Kids 3",
		class: "3",
		classRange: "2–4",
		base: "English Formula Kids 3",
		hours: 96,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 2,
		priceIdGroup3: 3,
		priceIdGroup2: 4,
		priceIdIndividual: 5,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 4,
		code: "EF1.4",
		name: "Английский для детей. Часть 4 из 5 — модуль A1 с подготовкой к пробному экзамену A1",
		alias: "Kids 4",
		class: "4",
		classRange: "3–5",
		base: "English Formula Kids 4",
		hours: 96,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 2,
		priceIdGroup3: 3,
		priceIdGroup2: 4,
		priceIdIndividual: 5,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 5,
		code: "EF1.5",
		name: "Английский для детей. Часть 5 из 5 — модуль A1 с подготовкой к экзамену A1",
		alias: "Kids 5",
		class: "5",
		classRange: "4–5",
		base: "English Formula Kids 5",
		hours: 96,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 2,
		priceIdGroup3: 3,
		priceIdGroup2: 4,
		priceIdIndividual: 5,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 6,
		code: "EF2.0",
		name: "Английский для подростков. Подготовительная программа. Модуль A1",
		alias: "Teens Starter",
		class: "6",
		classRange: "5–6",
		base: "English Formula Teens Starter",
		hours: 96,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 31,
		priceIdGroup3: 32,
		priceIdGroup2: 33,
		priceIdIndividual: 34,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 7,
		code: "EF2.1",
		name: "Английский для подростков. Часть 1 из 5 — модуль A2 с подготовкой к экзамену A2",
		alias: "Teens 1",
		class: "6",
		classRange: "5–7",
		base: "English Formula Teens 1",
		hours: 144,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 6,
		priceIdGroup3: 7,
		priceIdGroup2: 8,
		priceIdIndividual: 9,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 8,
		code: "EF2.2",
		name: "Английский для подростков. Часть 2 из 5 — модуль B1 с подготовкой к пробному экзамену B1",
		alias: "Teens 2",
		class: "7",
		classRange: "6–8",
		base: "English Formula Teens 2",
		hours: 144,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 6,
		priceIdGroup3: 7,
		priceIdGroup2: 8,
		priceIdIndividual: 9,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 9,
		code: "EF2.3",
		name: "Английский для подростков. Часть 3 из 5 — модуль B1+ с подготовкой к экзамену B1",
		alias: "Teens 3",
		class: "8",
		classRange: "7–9",
		base: "English Formula Teens 3",
		hours: 144,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 6,
		priceIdGroup3: 7,
		priceIdGroup2: 8,
		priceIdIndividual: 9,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 10,
		code: "EF2.4",
		name: "Английский для подростков. Часть 4 из 5 — модуль B2 с подготовкой к пробному экзамену B2",
		alias: "Teens 4",
		class: "9",
		classRange: "8–10",
		base: "English Formula Teens 4",
		hours: 144,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 6,
		priceIdGroup3: 7,
		priceIdGroup2: 8,
		priceIdIndividual: 9,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 11,
		code: "EF2.5",
		name: "Английский для подростков. Часть 5 из 5 — модуль B2 с подготовкой к экзамену B2",
		alias: "Teens 5",
		class: "10",
		classRange: "9–11",
		base: "English Formula Teens 5",
		hours: 144,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 6,
		priceIdGroup3: 7,
		priceIdGroup2: 8,
		priceIdIndividual: 9,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 12,
		code: "EF3.1",
		name: "Общий английский для взрослых. Часть 1 из 6 — уровень Starter",
		alias: "Adults Starter",
		base: "English Formula General Starter",
		hours: 72,
		maxLengthWeeks: 24,
		maxLengthMonths: 6,
		priceIdGroup4to12: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 10,
		priceIdIndividual: 7,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: false,
		years: [0, 1]
	},
	{
		id: 13,
		code: "EF3.2",
		name: "Общий английский для взрослых. Часть 2 из 6 — уровень Elementary",
		alias: "Adults Elementary",
		base: "English Formula General Elementary",
		hours: 108,
		maxLengthWeeks: 36,
		maxLengthMonths: 9,
		priceIdGroup4to12: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 11,
		priceIdIndividual: 8,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: false,
		years: [0, 1]
	},
	{
		id: 14,
		code: "EF3.3",
		name: "Общий английский для взрослых. Часть 3 из 6 — уровень Pre-Intermediate",
		alias: "Adults Pre-Intermediate",
		base: "English Formula General Pre-Intermediate",
		hours: 108,
		maxLengthWeeks: 36,
		maxLengthMonths: 9,
		priceIdGroup4to12: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 12,
		priceIdIndividual: 13,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: false,
		years: [0, 1]
	},
	{
		id: 15,
		code: "EF3.4",
		name: "Общий английский для взрослых. Часть 4 из 6 — уровень Intermediate",
		alias: "Adults Intermediate",
		base: "English Formula General Intermediate",
		hours: 108,
		maxLengthWeeks: 36,
		maxLengthMonths: 9,
		priceIdGroup4to12: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 12,
		priceIdIndividual: 13,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: false,
		years: [0, 1]
	},
	{
		id: 16,
		code: "EF3.5",
		name: "Общий английский для взрослых. Часть 5 из 6 — уровень Upper Intermediate",
		alias: "Adults Upper Intermediate",
		base: "English Formula General Upper Intermediate",
		hours: 108,
		maxLengthWeeks: 36,
		maxLengthMonths: 9,
		priceIdGroup4to12: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 14,
		priceIdIndividual: 15,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: false,
		years: [0, 1]
	},
	{
		id: 17,
		code: "EF3.6",
		name: "Общий английский для взрослых. Часть 6 из 6 — уровень Advanced",
		alias: "Adults Advanced",
		base: "English Formula General Advanced",
		hours: 108,
		maxLengthWeeks: 36,
		maxLengthMonths: 9,
		priceIdGroup4to12: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 14,
		priceIdIndividual: 15,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: false,
		years: [0, 1]
	},
	{
		id: 18,
		code: "EF4.1",
		name: "Деловой английский для взрослых. Часть 1 из 6 — уровень Starter",
		alias: "Adults Business Starter",
		base: "English Formula General Starter",
		hours: 72,
		maxLengthWeeks: 24,
		maxLengthMonths: 6,
		priceIdGroup4to12: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 10,
		priceIdIndividual: 7,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: false,
		years: [0, 1]
	},
	{
		id: 19,
		code: "EF4.2",
		name: "Деловой английский для взрослых. Часть 2 из 6 — уровень Elementary",
		alias: "Adults Business Elementary",
		base: "English Formula Business Elementary",
		hours: 108,
		maxLengthWeeks: 36,
		maxLengthMonths: 9,
		priceIdGroup4to12: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 12,
		priceIdIndividual: 13,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: false,
		years: [0, 1]
	},
	{
		id: 20,
		code: "EF4.3",
		name: "Деловой английский для взрослых. Часть 3 из 6 — уровень Pre-Intermediate",
		alias: "Adults Business Pre-Intermediate",
		base: "English Formula Business Pre-Intermediate",
		hours: 108,
		maxLengthWeeks: 36,
		maxLengthMonths: 9,
		priceIdGroup4to12: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 14,
		priceIdIndividual: 15,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: false,
		years: [0, 1]
	},
	{
		id: 21,
		code: "EF4.4",
		name: "Деловой английский для взрослых. Часть 4 из 6 — уровень Intermediate",
		alias: "Adults Business Intermediate",
		base: "English Formula Business Intermediate",
		hours: 108,
		maxLengthWeeks: 36,
		maxLengthMonths: 9,
		priceIdGroup4to12: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 14,
		priceIdIndividual: 15,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: false,
		years: [0, 1]
	},
	{
		id: 22,
		code: "EF4.5",
		name: "Деловой английский для взрослых. Часть 5 из 6 — уровень Upper Intermediate",
		alias: "Adults Business Upper Intermediate",
		base: "English Formula Business Upper Intermediate",
		hours: 108,
		maxLengthWeeks: 36,
		maxLengthMonths: 9,
		priceIdGroup4to12: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 13,
		priceIdIndividual: 16,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: false,
		years: [0, 1]
	},
	{
		id: 23,
		code: "EF4.6",
		name: "Деловой английский для взрослых. Часть 6 из 6 — уровень Advanced",
		alias: "Adults Business Advanced",
		base: "English Formula Business Advanced",
		hours: 108,
		maxLengthWeeks: 36,
		maxLengthMonths: 9,
		priceIdGroup4to12: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 13,
		priceIdIndividual: 16,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: false,
		years: [0, 1]
	},
	{
		id: 24,
		code: "EF5.1",
		name: "Подготовка к экзамену IELTS уровня 5–6.5",
		base: "Complete IELTS 5–6.5",
		alias: "IELTS 5–6.5",
		hours: 108,
		maxLengthWeeks: 36,
		maxLengthMonths: 9,
		priceIdGroup4to12: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 13,
		priceIdIndividual: 16,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: false,
		years: [0, 1]
	},
	{
		id: 25,
		code: "EF5.2",
		name: "Подготовка к экзамену IELTS уровня 6.5–7.5",
		base: "Complete IELTS 6.5–7.5",
		alias: "IELTS 6.5–7.5",
		hours: 108,
		maxLengthWeeks: 36,
		maxLengthMonths: 9,
		priceIdGroup4to12: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 13,
		priceIdIndividual: 16,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: false,
		years: [0, 1]
	},
	{
		id: 26,
		code: "EF5.3",
		name: "Подготовка к экзамену уровня B2",
		alias: "B2 First",
		base: "Complete First",
		hours: 108,
		maxLengthWeeks: 36,
		maxLengthMonths: 9,
		priceIdGroup4to12: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 13,
		priceIdIndividual: 16,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: false,
		years: [0, 1]
	},
	{
		id: 27,
		code: "A0",
		name: "Детский сад-школа. 0-й класс. Подготовка к школе. Формат \"полный день\"",
		alias: "0-й класс полный день",
		base: "Acadia School 0",
		hours: 720,
		maxLengthWeeks: 36,
		maxLengthMonths: 9,
		priceIdGroup4to12: 20,
		priceIdGroup4to12Education: 39,
		priceIdGroup4to12Care: 26,
		priceIdGroup3: 0,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 28,
		code: "A0",
		name: "Детский сад-школа. 0-й класс. Подготовка к школе. Формат \"до обеда\"",
		alias: "0-й класс до обеда",
		base: "Acadia School 0",
		hours: 720,
		maxLengthWeeks: 36,
		maxLengthMonths: 9,
		priceIdGroup4to12: 19,
		priceIdGroup4to12Education: 41,
		priceIdGroup4to12Care: 26,
		priceIdGroup3: 0,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 29,
		code: "A1",
		name: "Частная школа. 1-й класс. Формат \"полный день\"",
		alias: "1-й класс полный день",
		base: "Acadia School 1",
		hours: 660,
		maxLengthWeeks: 33,
		maxLengthMonths: 9,
		priceIdGroup4to12: 20,
		priceIdGroup4to12Education: 39,
		priceIdGroup4to12Care: 26,
		priceIdGroup3: 0,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 30,
		code: "A1",
		name: "Частная школа. 1-й класс. Формат \"до обеда\"",
		alias: "1-й класс до обеда",
		base: "Acadia School 1",
		hours: 660,
		maxLengthWeeks: 33,
		maxLengthMonths: 9,
		priceIdGroup4to12: 19,
		priceIdGroup4to12Education: 41,
		priceIdGroup4to12Care: 26,
		priceIdGroup3: 0,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 31,
		code: "A2",
		name: "Частная школа. 2-й класс. Формат \"полный день\"",
		alias: "2-й класс полный день",
		base: "Acadia School 2",
		hours: 680,
		maxLengthWeeks: 34,
		maxLengthMonths: 9,
		priceIdGroup4to12: 22,
		priceIdGroup4to12Education: 38,
		priceIdGroup4to12Care: 26,
		priceIdGroup3: 0,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 32,
		code: "A2",
		name: "Частная школа. 2-й класс. Формат \"до обеда\"",
		alias: "2-й класс до обеда",
		base: "Acadia School 2",
		hours: 680,
		maxLengthWeeks: 34,
		maxLengthMonths: 9,
		priceIdGroup4to12: 21,
		priceIdGroup4to12Education: 40,
		priceIdGroup4to12Care: 26,
		priceIdGroup3: 0,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 33,
		code: "A3",
		name: "Частная школа. 3-й класс. Формат \"полный день\"",
		alias: "3-й класс полный день",
		base: "Acadia School 3",
		hours: 680,
		maxLengthWeeks: 34,
		maxLengthMonths: 9,
		priceIdGroup4to12: 22,
		priceIdGroup4to12Education: 38,
		priceIdGroup4to12Care: 26,
		priceIdGroup3: 0,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 34,
		code: "A3",
		name: "Частная школа. 3-й класс. Формат \"до обеда\"",
		alias: "3-й класс до обеда",
		base: "Acadia School 3",
		hours: 680,
		maxLengthWeeks: 34,
		maxLengthMonths: 9,
		priceIdGroup4to12: 21,
		priceIdGroup4to12Education: 40,
		priceIdGroup4to12Care: 26,
		priceIdGroup3: 0,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 35,
		code: "A4",
		name: "Частная школа. 4-й класс. Формат \"полный день\"",
		alias: "4-й класс полный день",
		base: "Acadia School 4",
		hours: 680,
		maxLengthWeeks: 34,
		maxLengthMonths: 9,
		priceIdGroup4to12: 22,
		priceIdGroup4to12Education: 38,
		priceIdGroup4to12Care: 26,
		priceIdGroup3: 0,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 36,
		code: "A4",
		name: "Частная школа. 4-й класс. Формат \"до обеда\"",
		alias: "4-й класс до обеда",
		base: "Acadia School 4",
		hours: 680,
		maxLengthWeeks: 34,
		maxLengthMonths: 9,
		priceIdGroup4to12: 21,
		priceIdGroup4to12Education: 40,
		priceIdGroup4to12Care: 26,
		priceIdGroup3: 0,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 37,
		code: "A5",
		name: "Частная школа. 5-й класс. Формат \"полный день\"",
		alias: "5-й класс полный день",
		base: "Acadia School 5",
		hours: 680,
		maxLengthWeeks: 34,
		maxLengthMonths: 9,
		priceIdGroup4to12: 22,
		priceIdGroup4to12Education: 38,
		priceIdGroup4to12Care: 26,
		priceIdGroup3: 0,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 38,
		code: "A5",
		name: "Частная школа. 5-й класс. Формат \"до обеда\"",
		alias: "5-й класс до обеда",
		base: "Acadia School 5",
		hours: 680,
		maxLengthWeeks: 34,
		maxLengthMonths: 9,
		priceIdGroup4to12: 21,
		priceIdGroup4to12Education: 40,
		priceIdGroup4to12Care: 26,
		priceIdGroup3: 0,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 39,
		code: "KV2",
		name: "Повелители книг. Чтение, письмо, счёт и логика. Дневной формат",
		alias: "2 года до школы дневной формат",
		base: "Acadia Kids V2",
		hours: 288,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 42,
		priceIdGroup3: 11,
		priceIdGroup2: 43,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 40,
		code: "KE2",
		name: "Повелители книг. Чтение, письмо, счёт и логика. Вечерний формат",
		alias: "2 года до школы вечерний формат",
		base: "Acadia Kids E2",
		hours: 144,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 23,
		priceIdGroup3: 24,
		priceIdGroup2: 25,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 41,
		code: "KE1",
		name: "Мастера знаний. Подготовка к школе. Вечерний формат",
		alias: "1 год до школы вечерний формат",
		base: "Acadia Kids E1",
		hours: 144,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 23,
		priceIdGroup3: 24,
		priceIdGroup2: 25,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 42,
		code: "C1",
		name: "Летний городской лагерь для дошкольников",
		alias: "Летний лагерь дошкольники",
		base: "Acadia Camp Preschool",
		hours: 20,
		maxLengthWeeks: 1,
		maxLengthMonths: 1,
		priceIdGroup4to12: 26,
		priceIdGroup4to12Education: 29,
		priceIdGroup4to12Care: 30,
		priceIdGroup3: 0,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: false,
		years: [0]
	},
	{
		id: 43,
		code: "C2",
		name: "Летний городской лагерь для начальной школы",
		alias: "Летний лагерь школьники",
		base: "Acadia Camp Primary",
		hours: 20,
		maxLengthWeeks: 1,
		maxLengthMonths: 1,
		priceIdGroup4to12: 26,
		priceIdGroup4to12Education: 29,
		priceIdGroup4to12Care: 30,
		priceIdGroup3: 0,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: false,
		years: [0]
	},
	{
		id: 44,
		code: "EF2.6",
		name: "Английский для подростков. Подготовка к ЕГЭ",
		alias: "Teens ЕГЭ",
		class: "11",
		classRange: "11",
		base: "MacMillan ЕГЭ",
		hours: 96,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 31,
		priceIdGroup3: 32,
		priceIdGroup2: 33,
		priceIdIndividual: 34,
		priceValidFrom: '01.04.2023',
		earlyPaymentPossible: true,
		years: [0]
	},
	{
		id: 45,
		code: "EF1.1",
		name: "Английский для детей. Часть 1 из 5 — модуль Старт",
		alias: "Kids 1",
		class: "1",
		classRange: "1–2",
		base: "English Formula Kids 1",
		hours: 64,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 44,
		priceIdGroup3: 44,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 46,
		code: "EF1.2",
		name: "Английский для детей. Часть 2 из 5 — модуль Pre-A1 с подготовкой к пробному экзамену Pre-A1",
		alias: "Kids 2",
		class: "2",
		classRange: "1–3",
		base: "English Formula Kids 2",
		hours: 96,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 45,
		priceIdGroup3: 45,
		priceIdGroup2: 46,
		priceIdIndividual: 47,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 47,
		code: "EF1.3",
		name: "Английский для детей. Часть 3 из 5 — модуль Pre-A1 с подготовкой к экзамену Pre-A1",
		alias: "Kids 3",
		class: "3",
		classRange: "2–4",
		base: "English Formula Kids 3",
		hours: 96,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 45,
		priceIdGroup3: 45,
		priceIdGroup2: 46,
		priceIdIndividual: 47,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 48,
		code: "EF1.4",
		name: "Английский для детей. Часть 4 из 5 — модуль A1 с подготовкой к пробному экзамену A1",
		alias: "Kids 4",
		class: "4",
		classRange: "3–5",
		base: "English Formula Kids 4",
		hours: 96,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 45,
		priceIdGroup3: 45,
		priceIdGroup2: 46,
		priceIdIndividual: 47,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 49,
		code: "EF1.5",
		name: "Английский для детей. Часть 5 из 5 — модуль A1 с подготовкой к экзамену A1",
		alias: "Kids 5",
		class: "5",
		classRange: "4–5",
		base: "English Formula Kids 5",
		hours: 96,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 45,
		priceIdGroup3: 45,
		priceIdGroup2: 46,
		priceIdIndividual: 47,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 50,
		code: "EF2.0",
		name: "Английский для подростков. Подготовительная программа. Модуль A1",
		alias: "Teens Starter",
		class: "6",
		classRange: "5–6",
		base: "English Formula Teens Starter",
		hours: 96,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 0,
		priceIdIndividual: 34,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 51,
		code: "EF2.1",
		name: "Английский для подростков. Часть 1 из 5 — модуль A2 с подготовкой к экзамену A2",
		alias: "Teens 1",
		class: "6",
		classRange: "5–7",
		base: "English Formula Teens 1",
		hours: 144,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 48,
		priceIdGroup3: 48,
		priceIdGroup2: 13,
		priceIdIndividual: 16,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 52,
		code: "EF2.2",
		name: "Английский для подростков. Часть 2 из 5 — модуль B1 с подготовкой к пробному экзамену B1",
		alias: "Teens 2",
		class: "7",
		classRange: "6–8",
		base: "English Formula Teens 2",
		hours: 144,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 48,
		priceIdGroup3: 48,
		priceIdGroup2: 13,
		priceIdIndividual: 16,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 53,
		code: "EF2.3",
		name: "Английский для подростков. Часть 3 из 5 — модуль B1+ с подготовкой к экзамену B1",
		alias: "Teens 3",
		class: "8",
		classRange: "7–9",
		base: "English Formula Teens 3",
		hours: 144,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 48,
		priceIdGroup3: 48,
		priceIdGroup2: 13,
		priceIdIndividual: 16,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 54,
		code: "EF2.4",
		name: "Английский для подростков. Часть 4 из 5 — модуль B2 с подготовкой к пробному экзамену B2",
		alias: "Teens 4",
		class: "9",
		classRange: "8–10",
		base: "English Formula Teens 4",
		hours: 144,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 48,
		priceIdGroup3: 48,
		priceIdGroup2: 13,
		priceIdIndividual: 16,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 55,
		code: "EF2.5",
		name: "Английский для подростков. Часть 5 из 5 — модуль B2 с подготовкой к экзамену B2",
		alias: "Teens 5",
		class: "10",
		classRange: "9–11",
		base: "English Formula Teens 5",
		hours: 144,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 48,
		priceIdGroup3: 48,
		priceIdGroup2: 13,
		priceIdIndividual: 16,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 56,
		code: "EF2.6",
		name: "Английский для подростков. Подготовка к ЕГЭ",
		alias: "Teens ЕГЭ",
		class: "11",
		classRange: "11",
		base: "MacMillan ЕГЭ",
		hours: 96,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 6,
		priceIdGroup3: 6,
		priceIdGroup2: 8,
		priceIdIndividual: 9,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 57,
		code: "A0",
		name: "Детский сад-школа. 0-й класс. Подготовка к школе",
		alias: "0-й класс",
		base: "Acadia School 0",
		hours: 720,
		maxLengthWeeks: 36,
		maxLengthMonths: 9,
		priceIdGroup4to12: 49,
		priceIdGroup3: 49,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 58,
		code: "A1",
		name: "Частная школа. 1-й класс",
		alias: "1-й класс",
		base: "Acadia School 1",
		hours: 660,
		maxLengthWeeks: 33,
		maxLengthMonths: 9,
		priceIdGroup4to12: 49,
		priceIdGroup3: 49,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 59,
		code: "A2",
		name: "Частная школа. 2-й класс",
		alias: "2-й класс",
		base: "Acadia School 2",
		hours: 680,
		maxLengthWeeks: 34,
		maxLengthMonths: 9,
		priceIdGroup4to12: 49,
		priceIdGroup3: 49,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 60,
		code: "A3",
		name: "Частная школа. 3-й класс",
		alias: "3-й класс",
		base: "Acadia School 3",
		hours: 680,
		maxLengthWeeks: 34,
		maxLengthMonths: 9,
		priceIdGroup4to12: 49,
		priceIdGroup3: 49,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 61,
		code: "A4",
		name: "Частная школа. 4-й класс",
		alias: "4-й класс",
		base: "Acadia School 4",
		hours: 680,
		maxLengthWeeks: 34,
		maxLengthMonths: 9,
		priceIdGroup4to12: 49,
		priceIdGroup3: 49,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 62,
		code: "A5",
		name: "Частная школа. 5-й класс",
		alias: "5-й класс",
		base: "Acadia School 5",
		hours: 680,
		maxLengthWeeks: 34,
		maxLengthMonths: 9,
		priceIdGroup4to12: 50,
		priceIdGroup3: 50,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 63,
		code: "A6",
		name: "Частная школа. 6-й класс",
		alias: "6-й класс",
		base: "Acadia School 6",
		hours: 680,
		maxLengthWeeks: 34,
		maxLengthMonths: 9,
		priceIdGroup4to12: 50,
		priceIdGroup3: 50,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 64,
		code: "KV2",
		name: "Развивающие занятия. Дневной формат",
		alias: "2 года до школы дневной формат",
		base: "Acadia Kids V2",
		hours: 288,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 51,
		priceIdGroup3: 51,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 65,
		code: "KE2",
		name: "Развивающие занятия. Вечерний формат",
		alias: "2 года до школы вечерний формат",
		base: "Acadia Kids E2",
		hours: 144,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 52,
		priceIdGroup3: 52,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 66,
		code: "KE1",
		name: "Развивающие занятия с подготовкой к школе. Вечерний формат",
		alias: "1 год до школы вечерний формат",
		base: "Acadia Kids E1",
		hours: 144,
		maxLengthWeeks: 32,
		maxLengthMonths: 9,
		priceIdGroup4to12: 52,
		priceIdGroup3: 52,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 67,
		code: "A0",
		name: "Детский сад-школа. 0-й класс. Подготовка к школе. Формат «до обеда»",
		alias: "0-й класс до обеда",
		base: "Acadia School 0",
		hours: 720,
		maxLengthWeeks: 36,
		maxLengthMonths: 9,
		priceIdGroup4to12: 53,
		priceIdGroup3: 53,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 68,
		code: "A1",
		name: "Частная школа. 1-й класс. Формат «до обеда»",
		alias: "1-й класс до обеда",
		base: "Acadia School 1",
		hours: 660,
		maxLengthWeeks: 33,
		maxLengthMonths: 9,
		priceIdGroup4to12: 53,
		priceIdGroup3: 53,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 69,
		code: "A2",
		name: "Частная школа. 2-й класс Формат «до обеда»",
		alias: "2-й класс до обеда",
		base: "Acadia School 2",
		hours: 680,
		maxLengthWeeks: 34,
		maxLengthMonths: 9,
		priceIdGroup4to12: 53,
		priceIdGroup3: 53,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 70,
		code: "A3",
		name: "Частная школа. 3-й класс Формат «до обеда»",
		alias: "3-й класс до обеда",
		base: "Acadia School 3",
		hours: 680,
		maxLengthWeeks: 34,
		maxLengthMonths: 9,
		priceIdGroup4to12: 53,
		priceIdGroup3: 53,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 71,
		code: "A4",
		name: "Частная школа. 4-й класс Формат «до обеда»",
		alias: "4-й класс до обеда",
		base: "Acadia School 4",
		hours: 680,
		maxLengthWeeks: 34,
		maxLengthMonths: 9,
		priceIdGroup4to12: 53,
		priceIdGroup3: 53,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 72,
		code: "A5",
		name: "Частная школа. 5-й класс Формат «до обеда»",
		alias: "5-й класс до обеда",
		base: "Acadia School 5",
		hours: 680,
		maxLengthWeeks: 34,
		maxLengthMonths: 9,
		priceIdGroup4to12: 54,
		priceIdGroup3: 54,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 73,
		code: "A6",
		name: "Частная школа. 6-й класс Формат «до обеда»",
		alias: "6-й класс до обеда",
		base: "Acadia School 6",
		hours: 680,
		maxLengthWeeks: 34,
		maxLengthMonths: 9,
		priceIdGroup4to12: 54,
		priceIdGroup3: 54,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: true,
		years: [1]
	},
	{
		id: 74,
		code: "C2",
		name: "Летний городской лагерь для начальной школы. Пакет «1 неделя»",
		alias: "Летний лагерь школьники. 1 неделя",
		base: "Acadia Camp Primary",
		hours: 20,
		maxLengthWeeks: 1,
		maxLengthMonths: 1,
		priceIdGroup4to12: 55,
		priceIdGroup4to12Education: 0,
		priceIdGroup4to12Care: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: false,
		years: [1]
	},
	{
		id: 75,
		code: "C2",
		name: "Летний городской лагерь для начальной школы. Пакет «2 недели»",
		alias: "Летний лагерь школьники. 2 недели",
		base: "Acadia Camp Primary",
		hours: 20,
		maxLengthWeeks: 1,
		maxLengthMonths: 1,
		priceIdGroup4to12: 56,
		priceIdGroup4to12Education: 0,
		priceIdGroup4to12Care: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: false,
		years: [1]
	},
	{
		id: 76,
		code: "C2",
		name: "Летний городской лагерь для начальной школы. Пакет «3 недели»",
		alias: "Летний лагерь школьники. 3 недели",
		base: "Acadia Camp Primary",
		hours: 20,
		maxLengthWeeks: 1,
		maxLengthMonths: 1,
		priceIdGroup4to12: 57,
		priceIdGroup4to12Education: 0,
		priceIdGroup4to12Care: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: false,
		years: [1]
	},
	{
		id: 77,
		code: "C2",
		name: "Летний городской лагерь для начальной школы. Пакет «4 недели»",
		alias: "Летний лагерь школьники. 4 недели",
		base: "Acadia Camp Primary",
		hours: 20,
		maxLengthWeeks: 1,
		maxLengthMonths: 1,
		priceIdGroup4to12: 58,
		priceIdGroup4to12Education: 0,
		priceIdGroup4to12Care: 0,
		priceIdGroup3: 0,
		priceIdGroup2: 0,
		priceIdIndividual: 0,
		priceValidFrom: '01.04.2024',
		earlyPaymentPossible: false,
		years: [1]
	}
]

export default programs;