import { ChangeEvent, KeyboardEvent, MutableRefObject, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import s from './ObjectPronouns.module.css';

const ClosedEye: React.FC<{}> = () => {
	return (
		<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M21.0006 12.0007C19.2536 15.5766 15.8779 18 12 18M12 18C8.12204 18 4.7463 15.5766 2.99977 12.0002M12 18L12 21M19.4218 14.4218L21.4999 16.5M16.2304 16.9687L17.5 19.5M4.57812 14.4218L2.5 16.5M7.76953 16.9687L6.5 19.5" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
		</svg>
	);
}

const OpenEye: React.FC<{}> = () => {
	return (
		<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M22 12.0002C20.2531 15.5764 15.8775 19 11.9998 19C8.12201 19 3.74646 15.5764 2 11.9998" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
			<path fill-rule="evenodd" clip-rule="evenodd" d="M22 12.0002C20.2531 8.42398 15.8782 5 12.0005 5C8.1227 5 3.74646 8.42314 2 11.9998" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
		</svg>
	);
}

const ObjectPronouns: React.FC<{}> = () => {
	const getRandomInt = (max: number): number => {
		return Math.floor(Math.random() * max + 1);
	}

	const prepositions = [
		{ id: 1, preposition: "With" },
		{ id: 2, preposition: "About" },
		{ id: 3, preposition: "Tell" },
		{ id: 4, preposition: "Give it to" },
		{ id: 5, preposition: "Listen to" },
		{ id: 6, preposition: "Help" },
		{ id: 7, preposition: "Look at" },
		{ id: 8, preposition: "After" },
		{ id: 9, preposition: "In front of" },
		{ id: 10, preposition: "Behind" },
		{ id: 11, preposition: "Afraid of" }
	]

	const pronouns = [
		{ id: 1, pronoun: "I", objectPronoun: "me" },
		{ id: 2, pronoun: "he", objectPronoun: "him" },
		{ id: 2, pronoun: "she", objectPronoun: "her" },
		{ id: 2, pronoun: "we", objectPronoun: "us" },
		{ id: 2, pronoun: "they", objectPronoun: "them" },
	]

	const [preposition, setPreposition] = useState<number>(getRandomInt(prepositions.length - 1));
	const [pronoun, setPronoun] = useState<number>(getRandomInt(pronouns.length - 1));

	const [correctAnswers, setCorrectAnswers] = useState<number>(0);
	const [correctAnswersPercentage, setCorrectAnswersPercentage] = useState<number>(0);
	const [incorrectAnswers, setIncorrectAnswers] = useState<number>(0);
	const [incorrectAnswersPercentage, setIncorrectAnswersPercentage] = useState<number>(0);
	const [totalAnswers, setTotalAnswers] = useState<number>(0);

	const [currentAnswer, setCurrentAnswer] = useState<string>('');

	function updateCurrentAnswer(e: ChangeEvent<HTMLInputElement>) {
		setCurrentAnswer(e.target.value);
	}

	function inputOnChangeHandler(e: ChangeEvent<HTMLInputElement>) {
		if (e.currentTarget.value.length <= 16 && /^[a-zA-Z\s]*$/.test(e.currentTarget.value)) {
			updateCurrentAnswer(e);
		}
	}

	const [lastAnswerCorrect, setLastAnswerCorrect] = useState<boolean>(false);
	const [lastAnswerIncorrect, setLastAnswerIncorrect] = useState<boolean>(false);

	function highlightCorrectAnswer() {
		setLastAnswerCorrect(true);
		setLastAnswerIncorrect(false);
	}

	function highlightIncorrectAnswer() {
		setLastAnswerIncorrect(true);
		setLastAnswerCorrect(false);
	}

	useEffect(() => {
		let resetAnswersHighlight = setTimeout(() => {
			setLastAnswerCorrect(false);
			setLastAnswerIncorrect(false);
		}, 3000);
		return () => clearTimeout(resetAnswersHighlight);
	}, [lastAnswerCorrect, lastAnswerIncorrect]);

	function checkAnswer() {
		if ('' !== currentAnswer) {
			setTotalAnswers(totalAnswers + 1);
			const receivedAnswerWordsArray = currentAnswer.toLowerCase().split(' ');
			const receivedAnswer = receivedAnswerWordsArray[receivedAnswerWordsArray.length - 1];
			if (pronouns[pronoun]['objectPronoun'] === receivedAnswer) {
				setCorrectAnswers(correctAnswers + 1);
				highlightCorrectAnswer();
				let updatedCorrectAnswerLog: CorrectAnswerType[] = [
					{
						question: prepositions[preposition]['preposition'] + ' (' + pronouns[pronoun]['pronoun'] + ')',
						givenAnswer: prepositions[preposition]['preposition'] + ' ' + receivedAnswer
					},
					...correctAnswerLog
				];
				setCorrectAnswerLog(updatedCorrectAnswerLog);
			} else {
				setIncorrectAnswers(incorrectAnswers + 1);
				highlightIncorrectAnswer();
				let updatedErrorLog: ErrorType[] = [
					{
						question: prepositions[preposition]['preposition'] + ' (' + pronouns[pronoun]['pronoun'] + ')',
						givenAnswer: prepositions[preposition]['preposition'] + ' ' + receivedAnswer,
						correctAnswer: prepositions[preposition]['preposition'] + ' ' + pronouns[pronoun]['objectPronoun'],
					},
					...errorLog
				];
				setErrorLog(updatedErrorLog);
			}
			setPreposition(getRandomInt(prepositions.length - 1));
			setPronoun(getRandomInt(pronouns.length - 1));
			setCurrentAnswer('');
		};
	}

	function buttonOnClickHandler() {
		checkAnswer();
	}

	function inputOnKeyUpHandler(e: KeyboardEvent<HTMLInputElement>) {
		if (e.key === "Enter") {
			checkAnswer();
		}
	}

	useEffect(() => {
		if (totalAnswers !== 0) {
			setCorrectAnswersPercentage(Math.ceil(correctAnswers / totalAnswers * 100));
			setIncorrectAnswersPercentage(Math.floor(incorrectAnswers / totalAnswers * 100));
		}
		answerInputRef.current.focus();
	}, [totalAnswers, correctAnswers, incorrectAnswers]);

	const [secondsPassed, setSecondsPassed] = useState<number>(0);

	useEffect(() => {
		const timer = setInterval(() => { setSecondsPassed(secondsPassed + 1) }, 1000);
		return () => clearInterval(timer);
	}, [secondsPassed]);

	function formatTime(seconds: number) {
		const h = Math.floor(seconds / 3600);
		const m = Math.floor((seconds % 3600) / 60);
		const s = Math.round(seconds % 60);
		return [
			h,
			m > 9 ? m : (h ? '0' + m : m || '0'),
			s > 9 ? s : '0' + s
		].filter(Boolean).join(':');
	}

	type CorrectAnswerType = {
		question: string;
		givenAnswer: string;
	}

	const [correctAnswerLog, setCorrectAnswerLog] = useState<CorrectAnswerType[]>([]);

	const [correctAnswerLogVisibility, setCorrectAnswerLogVisibility] = useState<boolean>(false);

	const showCorrectAnswerLogEye = () => {
		if (correctAnswerLog.length > 0) {
			if (correctAnswerLogVisibility) {
				return <OpenEye />
			} else {
				return <ClosedEye />
			}
		}
	}

	type ErrorType = {
		question: string;
		givenAnswer: string;
		correctAnswer: string;
	}

	const [errorLog, setErrorLog] = useState<ErrorType[]>([]);

	const [errorLogVisibility, setErrorLogVisibility] = useState<boolean>(false);

	const showErrorLogEye = () => {
		if (errorLog.length > 0) {
			if (errorLogVisibility) {
				return <OpenEye />
			} else {
				return <ClosedEye />
			}
		}
	}

	const toggleErrorLogVisibility = () => {
		setErrorLogVisibility(!errorLogVisibility);
		answerInputRef.current.focus();
	}

	const toggleCorrectAnswerLogVisibility = () => {
		setCorrectAnswerLogVisibility(!correctAnswerLogVisibility);
		answerInputRef.current.focus();
	}

	const answerInputRef = useRef() as MutableRefObject<HTMLInputElement>;;

	return (
		<div className={s.objectPronouns}>
			<Helmet>
				<title>Интерактивный тренажёр — Object Pronouns — Объектные местоимения | TheWiz — образовательная онлайн-платформа</title>
				<meta name="description" content="Интерактивные тренажёры с искусственным интеллектом позволяют детям и взрослым оттачивать свои навыки" />
			</Helmet>
			<h3>Object Pronouns</h3>
			<div><strong>Example</strong>: In front of (<em>we</em>)&nbsp;&mdash; In front of <em>us</em></div>
			<div className={s.objectPronouns__divider}></div>
			<div className={s.objectPronouns__taskElements}>
				<div className={s.objectPronouns__preposition}>{prepositions[preposition]['preposition']}</div>
				<div className={s.objectPronouns__pronoun}>{'(' + pronouns[pronoun]['pronoun'] + ')'}</div>
			</div>
			<div className={s.objectPronouns__answer}>
				<div>Answer:</div>
				<input
					className={s.objectPronouns__answerInput}
					value={currentAnswer}
					onChange={inputOnChangeHandler}
					onKeyUp={inputOnKeyUpHandler}
					autoFocus
					ref={answerInputRef}
				/>
			</div>
			<div>
				<button onClick={buttonOnClickHandler}>Submit</button>
			</div>
			<div className={s.objectPronouns__divider}></div>
			<div className={s.objectPronouns__answerStatistics}>
				<div className={true === lastAnswerCorrect ? s.objectPronouns__correctAnswers + ' ' + s.active : s.objectPronouns__correctAnswers}>
					Answered correctly: <span>{correctAnswers}, {correctAnswersPercentage.toFixed(0)}&nbsp;%</span>
					<span className={s.objectPronouns__eye} onClick={toggleCorrectAnswerLogVisibility}>{showCorrectAnswerLogEye()}</span>
				</div>
				<div className={true === lastAnswerIncorrect ? s.objectPronouns__incorrectAnswers + ' ' + s.active : s.objectPronouns__incorrectAnswers}>
					Answered incorrectly: <span>{incorrectAnswers}, {incorrectAnswersPercentage.toFixed(0)}&nbsp;%</span>
					<span className={s.objectPronouns__eye} onClick={toggleErrorLogVisibility}>{showErrorLogEye()}</span>
				</div>
				<div>Total answers: {correctAnswers + incorrectAnswers}</div>
			</div>
			<div>Time elapsed: {formatTime(secondsPassed)}</div>
			<div className={true === correctAnswerLogVisibility ? s.objectPronouns__log + ' ' + s.objectPronouns__log_visible : s.objectPronouns__log}>
				<h4>Correct Answers</h4>
				<div className={s.objectPronouns__correctAnswersList}>
					<div><span className={s.objectPronouns__correctLogAnswerQuestion}>Question</span></div>
					<div>Answer</div>
				</div>
				{correctAnswerLog.map((el) => {
					return <div className={s.objectPronouns__correctAnswersList}>
						<div><span className={s.objectPronouns__correctAnswerLogQuestion}>{el.question}</span></div>
						<div><span className={s.objectPronouns__correctAnswerLogAnswer}>{el.givenAnswer}</span></div>
					</div>
				})}
			</div>
			<div className={true === errorLogVisibility ? s.objectPronouns__log + ' ' + s.objectPronouns__log_visible : s.objectPronouns__log}>
				<h4>Mistakes</h4>
				<div className={s.objectPronouns__errorList}>
					<div><span className={s.objectPronouns__errorLogQuestion}>Question</span></div>
					<div>Answer</div>
					<div><span className={s.objectPronouns__errorLogCorrectAnswer}>Correct Answer</span></div>
				</div>
				{errorLog.map((el) => {
					return <div className={s.objectPronouns__errorList}>
						<div><span className={s.objectPronouns__errorLogQuestion}>{el.question}</span></div>
						<div><span className={s.objectPronouns__errorLogAnswer}>{el.givenAnswer}</span></div>
						<div><span className={s.objectPronouns__errorLogCorrectAnswer}>{el.correctAnswer}</span></div>	
					</div>
				})}
			</div>
		</div>
	);
}

export default ObjectPronouns;