import s from './Price.module.css';
import { ChangeEvent, useEffect, useState } from "react";
import programs, { ProgramType } from '../../../../data/program/programData';
import courses, { CourseType } from '../../../../data/course/courseData';
import prices from '../../../../data/price/priceData';
import { numberWithSeparators, processDateFromInput, paymentsText, getMonthFromDateInput, getYearFromDateInput } from '../../../../library/functions';
import { Helmet } from 'react-helmet';
import CopyToClipboardTheWiz from '../../../Core/CopyToClipboardTheWiz/CopyToClipboardTheWiz';

const paymentData = (price: number, programMaxLengthMonths: number, discountFactor: number = 1, earlyPayment: boolean = false, priceEducation?: number, priceCare?: number, BonusMode: boolean = false, singleInstallmentDate: string = '') => {
	let fullpaymentInstallmentPeriodMonths = '';

	switch (programMaxLengthMonths) {
		case 9:
			switch (getMonthFromDateInput(singleInstallmentDate)) {
				case '01':
				case '02':
				case '03':
				case '04':
				case '05':
				case '06':
				case '07':
				case '08':
					fullpaymentInstallmentPeriodMonths = `с сентября ${parseInt(getYearFromDateInput(singleInstallmentDate))} года по апрель ${parseInt(getYearFromDateInput(singleInstallmentDate)) + 1} года`;
					break;
				case '09':
					fullpaymentInstallmentPeriodMonths = `с октября ${parseInt(getYearFromDateInput(singleInstallmentDate))} года по май ${parseInt(getYearFromDateInput(singleInstallmentDate)) + 1} года`;
					break;
				case '10':
					fullpaymentInstallmentPeriodMonths = `с ноября ${parseInt(getYearFromDateInput(singleInstallmentDate))} года по июнь ${parseInt(getYearFromDateInput(singleInstallmentDate)) + 1} года`;
					break;
				case '11':
					fullpaymentInstallmentPeriodMonths = `с декабря ${parseInt(getYearFromDateInput(singleInstallmentDate))} года по июль ${parseInt(getYearFromDateInput(singleInstallmentDate)) + 1} года`;
					break;
				case '12':
					fullpaymentInstallmentPeriodMonths = `с января по август ${parseInt(getYearFromDateInput(singleInstallmentDate)) + 1} года`;
					break;
			}
			break;
		case 6:
			switch (getMonthFromDateInput(singleInstallmentDate)) {
				case '01':
				case '02':
				case '03':
				case '04':
				case '05':
				case '06':
				case '07':
				case '08':
					fullpaymentInstallmentPeriodMonths = `с сентября ${parseInt(getYearFromDateInput(singleInstallmentDate))} года по январь ${parseInt(getYearFromDateInput(singleInstallmentDate)) + 1} года`;
					break;
				case '09':
					fullpaymentInstallmentPeriodMonths = `с октября ${parseInt(getYearFromDateInput(singleInstallmentDate))} года по февраль ${parseInt(getYearFromDateInput(singleInstallmentDate)) + 1} года`;
					break;
				case '10':
					fullpaymentInstallmentPeriodMonths = `с ноября ${parseInt(getYearFromDateInput(singleInstallmentDate))} года по март ${parseInt(getYearFromDateInput(singleInstallmentDate)) + 1} года`;
					break;
				case '11':
					fullpaymentInstallmentPeriodMonths = `с декабря ${parseInt(getYearFromDateInput(singleInstallmentDate))} года по апрель ${parseInt(getYearFromDateInput(singleInstallmentDate)) + 1} года`;
					break;
				case '12':
					fullpaymentInstallmentPeriodMonths = `с января по май ${parseInt(getYearFromDateInput(singleInstallmentDate)) + 1} года`;
					break;
			}
			break;
		case 4:
			switch (getMonthFromDateInput(singleInstallmentDate)) {
				case '01':
				case '02':
				case '03':
				case '04':
				case '05':
				case '06':
				case '07':
				case '08':
					fullpaymentInstallmentPeriodMonths = `с сентября по ноябрь ${parseInt(getYearFromDateInput(singleInstallmentDate))} года`;
					break;
				case '09':
					fullpaymentInstallmentPeriodMonths = `с октября по декабрь ${parseInt(getYearFromDateInput(singleInstallmentDate))} года`;
					break;
				case '10':
					fullpaymentInstallmentPeriodMonths = `с ноября ${parseInt(getYearFromDateInput(singleInstallmentDate))} года по январь ${parseInt(getYearFromDateInput(singleInstallmentDate)) + 1} года`;
					break;
				case '11':
					fullpaymentInstallmentPeriodMonths = `с декабря ${parseInt(getYearFromDateInput(singleInstallmentDate))} года по февраль ${parseInt(getYearFromDateInput(singleInstallmentDate)) + 1} года`;
					break;
				case '12':
					fullpaymentInstallmentPeriodMonths = `с января ${parseInt(getYearFromDateInput(singleInstallmentDate)) + 1} года по март ${parseInt(getYearFromDateInput(singleInstallmentDate)) + 1} года`;
					break;
			}
			break;
		case 3:
			switch (getMonthFromDateInput(singleInstallmentDate)) {
				case '01':
				case '02':
				case '03':
				case '04':
				case '05':
				case '06':
				case '07':
				case '08':
					fullpaymentInstallmentPeriodMonths = `с сентября по октябрь ${parseInt(getYearFromDateInput(singleInstallmentDate))} года`;
					break;
				case '09':
					fullpaymentInstallmentPeriodMonths = `с октября по ноябрь ${parseInt(getYearFromDateInput(singleInstallmentDate))} года`;
					break;
				case '10':
					fullpaymentInstallmentPeriodMonths = `с ноября по декабрь ${parseInt(getYearFromDateInput(singleInstallmentDate))} года`;
					break;
				case '11':
					fullpaymentInstallmentPeriodMonths = `с декабря ${parseInt(getYearFromDateInput(singleInstallmentDate))} года по январь ${parseInt(getYearFromDateInput(singleInstallmentDate)) + 1} года`;
					break;
				case '12':
					fullpaymentInstallmentPeriodMonths = `с января по февраль ${parseInt(getYearFromDateInput(singleInstallmentDate)) + 1} года`;
					break;
			}
			break;
		case 2:
			switch (getMonthFromDateInput(singleInstallmentDate)) {
				case '01':
				case '02':
				case '03':
				case '04':
				case '05':
				case '06':
				case '07':
				case '08':
					fullpaymentInstallmentPeriodMonths = `сентября ${parseInt(getYearFromDateInput(singleInstallmentDate))} года`;
					break;
				case '09':
					fullpaymentInstallmentPeriodMonths = `октября ${parseInt(getYearFromDateInput(singleInstallmentDate))} года`;
					break;
				case '10':
					fullpaymentInstallmentPeriodMonths = `ноября ${parseInt(getYearFromDateInput(singleInstallmentDate))} года`;
					break;
				case '11':
					fullpaymentInstallmentPeriodMonths = `декабря ${parseInt(getYearFromDateInput(singleInstallmentDate))} года`;
					break;
				case '12':
					fullpaymentInstallmentPeriodMonths = `января ${parseInt(getYearFromDateInput(singleInstallmentDate)) + 1} года`;
					break;
			}
			break;
	}

	if (!BonusMode) {
		return (
			<div>
				<div className={s.price__copiedContainer}>Одним платежом: <span className={s.price__fontMedium}>{numberWithSeparators(Math.round(price * discountFactor))}&nbsp;&#8381;</span> в срок до {processDateFromInput(singleInstallmentDate)}
					<CopyToClipboardTheWiz text={`${Math.round(price * discountFactor)} ₽ в срок до ${processDateFromInput(singleInstallmentDate)} г.`} />
				</div>
				{programMaxLengthMonths > 1 && (typeof priceEducation === 'undefined' || priceEducation === 0) && (typeof priceCare === 'undefined' || priceCare === 0) && earlyPayment === false && (
					<>
						<div className={s.price__divider}></div>
						<div>
							<span>В&nbsp;рассрочку: <span className={s.price__fontMedium}>{numberWithSeparators(Math.round(price * discountFactor / programMaxLengthMonths))}&nbsp;&#8381;</span> x {paymentsText(programMaxLengthMonths)}</span>
						</div>
						<div className={s.price__copiedContainer}>Первый платёж: <span className={s.price__fontMedium}>{numberWithSeparators(Math.round(price * discountFactor / programMaxLengthMonths))}&nbsp;&#8381;</span> в срок до {processDateFromInput(singleInstallmentDate)}
							<CopyToClipboardTheWiz text={`${Math.round(price * discountFactor / programMaxLengthMonths)} ₽ в срок до ${processDateFromInput(singleInstallmentDate)} г.`} />
						</div>
						<div className={s.price__copiedContainer}><span>Последующие платежи: </span>
							{programMaxLengthMonths === 2 && (<span>
								<span className={s.price__fontMedium}>{paymentsText(programMaxLengthMonths - 1)}</span> в размере <span className={s.price__fontMedium}>{numberWithSeparators(Math.round(price * discountFactor / programMaxLengthMonths))}&nbsp;&#8381;</span> до 10 {fullpaymentInstallmentPeriodMonths}
								<CopyToClipboardTheWiz text={`${paymentsText(programMaxLengthMonths - 1)} в размере ${Math.round(price * discountFactor / programMaxLengthMonths)} ₽ до 10 ${fullpaymentInstallmentPeriodMonths}`} />
							</span>)}
							{programMaxLengthMonths > 2 && (<span>
								<span className={s.price__fontMedium}>{paymentsText(programMaxLengthMonths - 1)}</span> по <span className={s.price__fontMedium}>{numberWithSeparators(Math.round(price * discountFactor / programMaxLengthMonths))}&nbsp;&#8381;</span> ежемесячно, до 10 числа каждого месяца, в период {fullpaymentInstallmentPeriodMonths}
								<CopyToClipboardTheWiz text={`${paymentsText(programMaxLengthMonths - 1)} по ${Math.round(price * discountFactor / programMaxLengthMonths)} ₽ ежемесячно, до 10 числа каждого месяца, в период ${fullpaymentInstallmentPeriodMonths}`} />
							</span>)}
						</div>
					</>
				)}
				{programMaxLengthMonths > 1 && (typeof priceEducation === 'undefined' || priceEducation === 0) && (typeof priceCare === 'undefined' || priceCare === 0) && earlyPayment === true && (
					<>
						<div className={s.price__copiedContainer}>Первый платёж: <span className={s.price__fontMedium}>{numberWithSeparators(Math.round(price * discountFactor - (Math.round(price * discountFactor * 0.75 / (programMaxLengthMonths - 1) / 100) * 100 * (programMaxLengthMonths - 1))))}&nbsp;&#8381;</span> в срок до {processDateFromInput(singleInstallmentDate)}
							<CopyToClipboardTheWiz text={`${Math.round(price * discountFactor - (Math.round(price * discountFactor * 0.75 / (programMaxLengthMonths - 1) / 100) * 100 * (programMaxLengthMonths - 1)))} ₽ в срок до ${processDateFromInput(singleInstallmentDate)} г.`} />
						</div>
						<div className={s.price__copiedContainer}><span>Последующие платежи: </span>
							{programMaxLengthMonths === 2 && (<span>
								<span className={s.price__fontMedium}>{paymentsText(programMaxLengthMonths - 1)}</span> в размере <span className={s.price__fontMedium}>{numberWithSeparators(Math.round(price * discountFactor * 0.75 / (programMaxLengthMonths - 1) / 100) * 100)}&nbsp;&#8381;</span> до 10 {fullpaymentInstallmentPeriodMonths}
								<CopyToClipboardTheWiz text={`${paymentsText(programMaxLengthMonths - 1)} в размере ${Math.round(price * discountFactor * 0.75 / (programMaxLengthMonths - 1) / 100) * 100} ₽ до 10 ${fullpaymentInstallmentPeriodMonths}`} />
							</span>)}
							{programMaxLengthMonths > 2 && (<span>
								<span className={s.price__fontMedium}>{paymentsText(programMaxLengthMonths - 1)}</span> по <span className={s.price__fontMedium}>{numberWithSeparators(Math.round(price * discountFactor * 0.75 / (programMaxLengthMonths - 1) / 100) * 100)}&nbsp;&#8381;</span> ежемесячно, до 10 числа каждого месяца, в период {fullpaymentInstallmentPeriodMonths}
								<CopyToClipboardTheWiz text={`${paymentsText(programMaxLengthMonths - 1)} по ${Math.round(price * discountFactor * 0.75 / (programMaxLengthMonths - 1) / 100) * 100} ₽ ежемесячно, до 10 числа каждого месяца, в период ${fullpaymentInstallmentPeriodMonths}`} />
							</span>)}
						</div>
					</>
				)}
				{discountFactor !== 1 && (
					<div>
						Экономия: <span className={s.price__fontMedium}>{numberWithSeparators(Math.round(price - price * discountFactor))}&nbsp;&#8381;</span>
					</div>
				)}
			</div>
		);
	} else {
		return (
			<div>
				<div className={s.price__copiedContainer}>
					Одним платежом: <span className={s.price__fontMedium}>{numberWithSeparators(price)}&nbsp;&#8381;</span> в срок до {processDateFromInput(singleInstallmentDate)}
					<CopyToClipboardTheWiz text={`${Math.round(price)} ₽ в срок до ${processDateFromInput(singleInstallmentDate)} г.`} />
				</div>
				{programMaxLengthMonths > 1 && earlyPayment === false && (
					<>
						<div className={s.price__divider}></div>
						<div>
							<span>В&nbsp;рассрочку: <span className={s.price__fontMedium}>{numberWithSeparators(price / programMaxLengthMonths)}&nbsp;&#8381;</span> x {programMaxLengthMonths}&nbsp;платежей</span>
							<div className={s.price__copiedContainer}>Первый платёж: <span className={s.price__fontMedium}>{numberWithSeparators(Math.round(price / programMaxLengthMonths))}&nbsp;&#8381;</span> в срок до {processDateFromInput(singleInstallmentDate)}
								<CopyToClipboardTheWiz text={`${Math.round(price / programMaxLengthMonths)} ₽ в срок до ${processDateFromInput(singleInstallmentDate)} г.`} />
							</div>
							<div className={s.price__copiedContainer}><span>Последующие платежи: </span>
								{programMaxLengthMonths === 2 && (<span>
									<span className={s.price__fontMedium}>{paymentsText(programMaxLengthMonths - 1)}</span> в размере <span className={s.price__fontMedium}>{numberWithSeparators(Math.round(price / programMaxLengthMonths))}&nbsp;&#8381;</span> до 10 {fullpaymentInstallmentPeriodMonths}
									<CopyToClipboardTheWiz text={`${paymentsText(programMaxLengthMonths - 1)} в размере ${Math.round(price / programMaxLengthMonths)} ₽ до 10 ${fullpaymentInstallmentPeriodMonths}`} />
								</span>)}
								{programMaxLengthMonths > 2 && (<span>
									<span className={s.price__fontMedium}>{paymentsText(programMaxLengthMonths - 1)}</span> по <span className={s.price__fontMedium}>{numberWithSeparators(Math.round(price / programMaxLengthMonths))}&nbsp;&#8381;</span> ежемесячно, до 10 числа каждого месяца, в период {fullpaymentInstallmentPeriodMonths}
									<CopyToClipboardTheWiz text={`${paymentsText(programMaxLengthMonths - 1)} по ${Math.round(price / programMaxLengthMonths)} ₽ ежемесячно, до 10 числа каждого месяца, в период ${fullpaymentInstallmentPeriodMonths}`} />
								</span>)}
							</div>
						</div>
					</>
				)}
				{programMaxLengthMonths > 1 && earlyPayment === true && (
					<>
						<div className={s.price__divider}></div>
						<div>
							<span>В&nbsp;рассрочку: на {programMaxLengthMonths}&nbsp;платежей</span>
							<div className={s.price__copiedContainer}>Первый платёж: <span className={s.price__fontMedium}>{numberWithSeparators(Math.round(price - (Math.round(price * 0.75 / (programMaxLengthMonths - 1) / 100) * 100 * (programMaxLengthMonths - 1))))}&nbsp;&#8381;</span> в срок до {processDateFromInput(singleInstallmentDate)}
								<CopyToClipboardTheWiz text={`${Math.round(price - (Math.round(price * 0.75 / (programMaxLengthMonths - 1) / 100) * 100 * (programMaxLengthMonths - 1)))} ₽ в срок до ${processDateFromInput(singleInstallmentDate)} г.`} />
							</div>
							<div className={s.price__copiedContainer}><span>Последующие платежи: </span>
								{programMaxLengthMonths === 2 && (<span>
									<span className={s.price__fontMedium}>{paymentsText(programMaxLengthMonths - 1)}</span> в размере <span className={s.price__fontMedium}>{numberWithSeparators(Math.round(price * 0.75 / (programMaxLengthMonths - 1) / 100) * 100)}&nbsp;&#8381;</span> до 10 {fullpaymentInstallmentPeriodMonths}
									<CopyToClipboardTheWiz text={`${paymentsText(programMaxLengthMonths - 1)} в размере ${Math.round(price * 0.75 / (programMaxLengthMonths - 1) / 100) * 100} ₽ до 10 ${fullpaymentInstallmentPeriodMonths}`} />
								</span>)}
								{programMaxLengthMonths > 2 && (<span>
									<span className={s.price__fontMedium}>{paymentsText(programMaxLengthMonths - 1)}</span> по <span className={s.price__fontMedium}>{numberWithSeparators(Math.round(price * 0.75 / (programMaxLengthMonths - 1) / 100) * 100)}&nbsp;&#8381;</span> ежемесячно, до 10 числа каждого месяца, в период {fullpaymentInstallmentPeriodMonths}
									<CopyToClipboardTheWiz text={`${paymentsText(programMaxLengthMonths - 1)} по ${Math.round(price * 0.75 / (programMaxLengthMonths - 1) / 100) * 100} ₽ ежемесячно, до 10 числа каждого месяца, в период ${fullpaymentInstallmentPeriodMonths}`} />
								</span>)}
							</div>
						</div>
					</>
				)}
				{discountFactor !== 1 && (
					<div>
						Количество бонусных баллов: <span className={s.price__fontMedium}>{numberWithSeparators(Math.round(price * (1 - discountFactor)))}</span>
					</div>
				)}
			</div>
		);
	}
}

type PriceDetailsPropsType = {
	programId: number
}

const PriceDetails = (props: PriceDetailsPropsType) => {
	const programData: ProgramType = programs.filter((el) => el.id === props.programId)[0];
	const courseData: CourseType[] = courses.filter((el) => el.programs.includes(props.programId));

	const [currentFormat, setCurrentFormat] = useState<string>("all");

	const setCurrentFormatHandler = (event: ChangeEvent<HTMLSelectElement>) => {
		setCurrentFormat(event.currentTarget.value);
	}

	let maxInstallments = 1;

	switch (programData.maxLengthMonths) {
		case 9:
			maxInstallments = 9;
			break;
		case 8:
		case 7:
		case 6:
			maxInstallments = 6;
			break;
		case 5:
		case 4:
			maxInstallments = 4;
			break;
		case 3:
			maxInstallments = 3;
			break;
		case 2:
			maxInstallments = 2;
			break;
	}

	const [installmentPeriodNoDiscount, setInstallmentPeriodNoDiscount] = useState<number>(maxInstallments);

	const installmentPeriodNoDiscountHandler = (event: ChangeEvent<HTMLInputElement>) => {
		switch (event.currentTarget.id) {
			case "installmentPeriodNoDiscount2":
				setInstallmentPeriodNoDiscount(2);
				break;
			case "installmentPeriodNoDiscount3":
				setInstallmentPeriodNoDiscount(3);
				break;
			case "installmentPeriodNoDiscount4":
				setInstallmentPeriodNoDiscount(4);
				break;
			case "installmentPeriodNoDiscount6":
				setInstallmentPeriodNoDiscount(6);
				break;
			case "installmentPeriodNoDiscount9":
				setInstallmentPeriodNoDiscount(9);
				break;
		}
	}

	const [checkboxNoDiscountState, setCheckboxNoDiscountState] = useState<boolean>(false);

	const checkboxNoDiscountHandler = () => {
		setCheckboxNoDiscountState(!checkboxNoDiscountState);
	}

	const [installmentPeriodDiscount, setInstallmentPeriodDiscount] = useState<number>(maxInstallments);

	const installmentPeriodDiscountHandler = (event: ChangeEvent<HTMLInputElement>) => {
		switch (event.currentTarget.id) {
			case "installmentPeriodDiscount2":
				setInstallmentPeriodDiscount(2);
				break;
			case "installmentPeriodDiscount3":
				setInstallmentPeriodDiscount(3);
				break;
			case "installmentPeriodDiscount4":
				setInstallmentPeriodDiscount(4);
				break;
			case "installmentPeriodDiscount6":
				setInstallmentPeriodDiscount(6);
				break;
			case "installmentPeriodDiscount9":
				setInstallmentPeriodDiscount(9);
				break;
		}
	}

	const [checkboxDiscountState, setCheckboxDiscountState] = useState<boolean>(false);

	const checkboxDiscountHandler = () => {
		setCheckboxDiscountState(!checkboxDiscountState);
	}

	const [discountPercentage, setDiscountPercentage] = useState<number>(10);

	const discountHandler = (event: ChangeEvent<HTMLInputElement>) => {
		switch (event.currentTarget.id) {
			case "discount5":
				setDiscountPercentage(5);
				break;
			case "discount10":
				setDiscountPercentage(10);
				break;
			case "discount20":
				setDiscountPercentage(20);
				break;
			case "discount30":
				setDiscountPercentage(30);
				break;
			case "discount40":
				setDiscountPercentage(40);
				break;
			case "discount50":
				setDiscountPercentage(50);
				break;
		}
	}

	const [installmentPeriodBonus, setInstallmentPeriodBonus] = useState<number>(maxInstallments);

	const installmentPeriodBonusHandler = (event: ChangeEvent<HTMLInputElement>) => {
		switch (event.currentTarget.id) {
			case "installmentPeriodBonus2":
				setInstallmentPeriodBonus(2);
				break;
			case "installmentPeriodBonus3":
				setInstallmentPeriodBonus(3);
				break;
			case "installmentPeriodBonus4":
				setInstallmentPeriodBonus(4);
				break;
			case "installmentPeriodBonus6":
				setInstallmentPeriodBonus(6);
				break;
			case "installmentPeriodBonus9":
				setInstallmentPeriodBonus(9);
				break;
		}
	}

	const [checkboxBonusState, setCheckboxBonusState] = useState<boolean>(false);

	const checkboxBonusHandler = () => {
		setCheckboxBonusState(!checkboxBonusState);
	}

	const [BonusPercentage, setBonusPercentage] = useState<number>(10);

	const BonusHandler = (event: ChangeEvent<HTMLInputElement>) => {
		switch (event.currentTarget.id) {
			case "Bonus5":
				setBonusPercentage(5);
				break;
			case "Bonus10":
				setBonusPercentage(10);
				break;
			case "Bonus20":
				setBonusPercentage(20);
				break;
			case "Bonus30":
				setBonusPercentage(30);
				break;
			case "Bonus40":
				setBonusPercentage(40);
				break;
			case "Bonus50":
				setBonusPercentage(50);
				break;
		}
	}

	const [checkboxEarlyPaymentDiscountState, setCheckboxEarlyPaymentDiscountState] = useState<boolean>(false);

	const checkboxEarlyPaymentDiscountStateHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setCheckboxEarlyPaymentDiscountState(!checkboxEarlyPaymentDiscountState);
	}

	const [earlyPaymentDiscount, setEarlyPaymentDiscount] = useState<number>(10);

	const earlyPaymentDiscountHandler = (event: ChangeEvent<HTMLInputElement>) => {
		switch (event.currentTarget.id) {
			case "earlyPaymentDiscount5":
				setEarlyPaymentDiscount(5);
				break;
			case "earlyPaymentDiscount10":
				setEarlyPaymentDiscount(10);
				break;
		}
	}

	type earlyPaymentDiscountPeriodsType = {
		discountPercentage: number,
		textPeriod: string
	}[];

	const earlyPaymentDiscountPeriods: earlyPaymentDiscountPeriodsType = [
		{
			discountPercentage: 10,
			textPeriod: "с 1 по 30 апреля"
		},
		{
			discountPercentage: 5,
			textPeriod: "с 1 по 31 мая"
		}
	];

	const earlyPaymentDiscountPeriod: string = earlyPaymentDiscountPeriods.filter((el) => el.discountPercentage === earlyPaymentDiscount)[0].textPeriod;

	const [checkboxEarlyPaymentBonusState, setCheckboxEarlyPaymentBonusState] = useState<boolean>(false);

	const checkboxEarlyPaymentBonusStateHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setCheckboxEarlyPaymentBonusState(!checkboxEarlyPaymentBonusState);
	}

	const [earlyPaymentBonus, setEarlyPaymentBonus] = useState<number>(15);

	const earlyPaymentBonusHandler = (event: ChangeEvent<HTMLInputElement>) => {
		switch (event.currentTarget.id) {
			case "earlyPaymentBonus5":
				setEarlyPaymentBonus(5);
				break;
			case "earlyPaymentBonus10":
				setEarlyPaymentBonus(10);
				break;
			case "earlyPaymentBonus15":
				setEarlyPaymentBonus(15);
				break;
		}
	}

	type earlyPaymentBonusPeriodsType = {
		BonusPercentage: number,
		textPeriod: string
	}[];

	const earlyPaymentBonusPeriods: earlyPaymentBonusPeriodsType = [
		{
			BonusPercentage: 15,
			textPeriod: "с 1 по 30 апреля"
		},
		{
			BonusPercentage: 10,
			textPeriod: "с 1 по 31 мая"
		},
		{
			BonusPercentage: 5,
			textPeriod: "с 1 по 30 июня"
		}
	];

	const earlyPaymentBonusPeriod: string = earlyPaymentBonusPeriods.filter((el) => el.BonusPercentage === earlyPaymentBonus)[0].textPeriod;

	const programsWithInstallment: number[] = [57, 58, 59, 60, 61, 62, 63];
	const installmentPrice: number = 25000;

	const [earlyPaymentDiscountInstallmentPeriod, setEarlyPaymentDiscountInstallmentPeriod] = useState<number>(9);

	const earlyPaymentDiscountInstallmentPeriodHandler = (event: ChangeEvent<HTMLInputElement>) => {
		switch (event.currentTarget.id) {
			case "earlyPaymentDiscountInstallmentPeriod3":
				setEarlyPaymentDiscountInstallmentPeriod(3);
				break;
			case "earlyPaymentDiscountInstallmentPeriod4":
				setEarlyPaymentDiscountInstallmentPeriod(4);
				break;
			case "earlyPaymentDiscountInstallmentPeriod6":
				setEarlyPaymentDiscountInstallmentPeriod(6);
				break;
			case "earlyPaymentDiscountInstallmentPeriod9":
				setEarlyPaymentDiscountInstallmentPeriod(9);
				break;
		}
	}

	const [earlyPaymentBonusInstallmentPeriod, setEarlyPaymentBonusInstallmentPeriod] = useState<number>(maxInstallments);

	const earlyPaymentBonusInstallmentPeriodHandler = (event: ChangeEvent<HTMLInputElement>) => {
		switch (event.currentTarget.id) {
			case "earlyPaymentBonusInstallmentPeriod3":
				setEarlyPaymentBonusInstallmentPeriod(3);
				break;
			case "earlyPaymentBonusInstallmentPeriod4":
				setEarlyPaymentBonusInstallmentPeriod(4);
				break;
			case "earlyPaymentBonusInstallmentPeriod6":
				setEarlyPaymentBonusInstallmentPeriod(6);
				break;
			case "earlyPaymentBonusInstallmentPeriod9":
				setEarlyPaymentBonusInstallmentPeriod(9);
				break;
		}
	}

	let todayDate = new Date();

	const [singleInstallmentDateNoDiscount, setSingleInstallmentDateNoDiscount] = useState<string>(todayDate.toISOString().substring(0, 10));

	const singleInstallmentDateNoDiscountChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setSingleInstallmentDateNoDiscount(event.currentTarget.value);
	}

	const [singleInstallmentDateDiscount, setSingleInstallmentDateDiscount] = useState<string>(todayDate.toISOString().substring(0, 10));

	const singleInstallmentDateDiscountChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setSingleInstallmentDateDiscount(event.currentTarget.value);
	}

	const [singleInstallmentDateBonus, setSingleInstallmentDateBonus] = useState<string>(todayDate.toISOString().substring(0, 10));

	const singleInstallmentDateBonusChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setSingleInstallmentDateBonus(event.currentTarget.value);
	}

	const [singleInstallmentDateEarlyPaymentDiscount, setSingleInstallmentDateEarlyPaymentDiscount] = useState<string>(todayDate.toISOString().substring(0, 10));

	const singleInstallmentDateEarlyPaymentDiscountChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const inputDate = new Date(event.currentTarget.value);
		const currentYear = new Date().getFullYear();

		const startApril = new Date(currentYear, 3, 1);
		const endApril = new Date(currentYear, 3, 30);

		const startMay = new Date(currentYear, 4, 1);
		const endMay = new Date(currentYear, 4, 31);

		switch (earlyPaymentDiscount) {
			case 10:
				if (inputDate >= startApril && inputDate <= endApril) {
					setSingleInstallmentDateEarlyPaymentDiscount(event.currentTarget.value);
				} else {
					setSingleInstallmentDateEarlyPaymentDiscount(todayDate.toISOString().substring(0, 10));
				}
				break;
			case 5:
				if (inputDate >= startMay && inputDate <= endMay) {
					setSingleInstallmentDateEarlyPaymentDiscount(event.currentTarget.value);
				} else {
					setSingleInstallmentDateEarlyPaymentDiscount(todayDate.toISOString().substring(0, 10));
				}
				break;
		}
	}

	const [singleInstallmentDateEarlyPaymentBonus, setSingleInstallmentDateEarlyPaymentBonus] = useState<string>(todayDate.toISOString().substring(0, 10));

	const singleInstallmentDateEarlyPaymentBonusChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
		const inputDate = new Date(event.currentTarget.value);
		const currentYear = new Date().getFullYear();

		const startApril = new Date(currentYear, 3, 1);
		const endApril = new Date(currentYear, 3, 30);

		const startMay = new Date(currentYear, 4, 1);
		const endMay = new Date(currentYear, 4, 31);

		const startJune = new Date(currentYear, 5, 1);
		const endJune = new Date(currentYear, 5, 30);

		switch (earlyPaymentBonus) {
			case 15:
				if (inputDate >= startApril && inputDate <= endApril) {
					setSingleInstallmentDateEarlyPaymentBonus(event.currentTarget.value);
				} else {
					setSingleInstallmentDateEarlyPaymentBonus(todayDate.toISOString().substring(0, 10));
				}
				break;
			case 10:
				if (inputDate >= startMay && inputDate <= endMay) {
					setSingleInstallmentDateEarlyPaymentBonus(event.currentTarget.value);
				} else {
					setSingleInstallmentDateEarlyPaymentBonus(todayDate.toISOString().substring(0, 10));
				}
				break;
			case 5:
				if (inputDate >= startJune && inputDate <= endJune) {
					setSingleInstallmentDateEarlyPaymentBonus(event.currentTarget.value);
				} else {
					setSingleInstallmentDateEarlyPaymentBonus(todayDate.toISOString().substring(0, 10));
				}
				break;
		}
	}

	if (programData) {
		const programPriceGroup4to12 = prices.filter((el) => el.id === programData.priceIdGroup4to12)[0].price;
		const programPriceGroup3 = prices.filter((el) => el.id === programData.priceIdGroup3)[0].price;
		const programPriceGroup2 = prices.filter((el) => el.id === programData.priceIdGroup2)[0].price;
		const programPriceIndividual = prices.filter((el) => el.id === programData.priceIdIndividual)[0].price;

		let programPriceGroup4to12Education = 0;
		let programPriceGroup4to12Care = 0;

		if (typeof programData.priceIdGroup4to12Education !== 'undefined' && programData.priceIdGroup4to12Education !== 0 && typeof programData.priceIdGroup4to12Care !== 'undefined' && programData.priceIdGroup4to12Education !== 0) {
			programPriceGroup4to12Education = prices.filter((el) => el.id === programData.priceIdGroup4to12Education)[0].price;
			programPriceGroup4to12Care = prices.filter((el) => el.id === programData.priceIdGroup4to12Care)[0].price;
		}

		return (
			<div className={s.price__programData}>
				<div className={s.price__programDataSections}>
					<h3>Расчёт</h3>
					<div className={s.price__programDataSection}>
						<h4>Данные программы</h4>
						<div className={s.price__programDataElements}>
							{Object.keys(courseData).length &&
								<div className={s.price__programDataElement}>
									<div>Входит в курс</div>
									<div>{courseData[0].name}</div>
								</div>
							}
							<div className={s.price__programDataElement}>
								<div>Название программы</div>
								<div>{programData.name}</div>
							</div>
							<div className={s.price__programDataElement}>
								<div>Код программы</div>
								<div>{programData.code}</div>
							</div>
							<div className={s.price__programDataElement}>
								<div>Базовый УМК</div>
								<div>{programData.base}</div>
							</div>
							{programData.class !== undefined && (
								<div className={s.price__programDataElement}>
									<div>Класс школы</div>
									<div>{programData.class}&nbsp;класс
										{programData.classRange !== "" && (
											<span> ({programData.classRange}&nbsp;классы)</span>
										)}
									</div>
								</div>
							)}
							<div className={s.price__programDataElement}>
								<div>Объем программы</div>
								<div>{programData.hours} астр. ч</div>
							</div>
							<div className={s.price__programDataElement}>
								<div>Срок освоения программы</div>
								<div>{programData.maxLengthMonths > 1 && (programData.maxLengthMonths + " мес. или ")}{programData.maxLengthWeeks} нед.</div>
							</div>
						</div>
					</div>
					<div className={s.price__programDataSection}>
						<h4>Базовая стоимость программы</h4>
						<div className={s.price__programDataElements}>
							{programPriceGroup4to12 !== 0 && (
								<div className={s.price__programDataElement}>
									<div>В&nbsp;группе из&nbsp;4–12&nbsp;учеников</div>
									<div><div><span className={s.price__fontMedium}>{numberWithSeparators(programPriceGroup4to12)}&nbsp;&#8381;</span></div>
									</div>
								</div>
							)}
							{programPriceGroup3 !== 0 &&
								<div className={s.price__programDataElement}>
									<div>В&nbsp;группе из&nbsp;3&nbsp;учеников</div>
									<div><span className={s.price__fontMedium}>{numberWithSeparators(programPriceGroup3)}&nbsp;&#8381;</span></div>
								</div>
							}
							{programPriceGroup2 !== 0 &&
								<div className={s.price__programDataElement}>
									<div>В&nbsp;группе из&nbsp;2&nbsp;учеников</div>
									<div><span className={s.price__fontMedium}>{numberWithSeparators(programPriceGroup2)}&nbsp;&#8381;</span></div>
								</div>
							}
							{programPriceIndividual !== 0 &&
								<div className={s.price__programDataElement}>
									<div>Индивидуально</div>
									<div><span className={s.price__fontMedium}>{numberWithSeparators(programPriceIndividual)}&nbsp;&#8381;</span></div>
								</div>
							}
							<div className={s.price__programDataElement}>
								<div>Цена действует</div>
								<div>с&nbsp;{programData.priceValidFrom}</div>
							</div>
						</div>
					</div>
				</div>
				<div className={s.price__programDataSections}>
					<h3>Схема оплаты</h3>
					<div className={s.price__selectors}>
						<div className={s.price__selector}>
							<div><b>Формат обучения:</b></div>
							<select value={currentFormat} onChange={setCurrentFormatHandler}>
								<option value="all">Все форматы</option>
								{programPriceGroup4to12 !== 0 && <option value="group4to12">В&nbsp;группе из&nbsp;4–12&nbsp;учеников</option>}
								{programPriceGroup3 !== 0 && <option value="group3">В&nbsp;группе из&nbsp;3&nbsp;учеников</option>}
								{programPriceGroup2 !== 0 && <option value="group2">В&nbsp;группе из&nbsp;2&nbsp;учеников</option>}
								{programPriceIndividual !== 0 && <option value="individual">Индивидуально</option>}
							</select>
						</div>
						<div className={s.price__selector}>
							<div><b>Варианты оплаты:</b></div>
							<label>
								<input type="checkbox" checked={checkboxNoDiscountState} onChange={checkboxNoDiscountHandler}></input>
								Полная оплата
							</label>
							<label>
								<input type="checkbox" checked={checkboxDiscountState} onChange={checkboxDiscountHandler}></input>
								Оплата со скидкой
							</label>
							<label>
								<input type="checkbox" checked={checkboxBonusState} onChange={checkboxBonusHandler}></input>
								Оплата с начислением бонусных баллов
							</label>
							{programData.earlyPaymentPossible === true && (<label>
								<input type="checkbox" checked={checkboxEarlyPaymentDiscountState} onChange={checkboxEarlyPaymentDiscountStateHandler}></input>
								Ранняя оплата со&nbsp;скидкой
							</label>)}
							{programData.earlyPaymentPossible === true && (<label>
								<input type="checkbox" checked={checkboxEarlyPaymentBonusState} onChange={checkboxEarlyPaymentBonusStateHandler}></input>
								Ранняя оплата с начислением бонусных баллов
							</label>)}
						</div>
						{checkboxDiscountState && (
							<div className={s.price__selector}>
								<div><b>Величина скидки:</b></div>
								<label>
									<input type="checkbox" id="discount50" checked={discountPercentage === 50} onChange={discountHandler}></input>
									50 %
								</label>
								<label>
									<input type="checkbox" id="discount40" checked={discountPercentage === 40} onChange={discountHandler}></input>
									40 %
								</label>
								<label>
									<input type="checkbox" id="discount30" checked={discountPercentage === 30} onChange={discountHandler}></input>
									30 %
								</label>
								<label>
									<input type="checkbox" id="discount20" checked={discountPercentage === 20} onChange={discountHandler}></input>
									20 %
								</label>
								<label>
									<input type="checkbox" id="discount10" checked={discountPercentage === 10} onChange={discountHandler}></input>
									10 %
								</label>
								<label>
									<input type="checkbox" id="discount5" checked={discountPercentage === 5} onChange={discountHandler}></input>
									5 %
								</label>
							</div>
						)}
						{checkboxBonusState && (
							<div className={s.price__selector}>
								<div><b>Величина бонусных баллов:</b></div>
								<label>
									<input type="checkbox" id="Bonus50" checked={BonusPercentage === 50} onChange={BonusHandler}></input>
									50 %
								</label>
								<label>
									<input type="checkbox" id="Bonus40" checked={BonusPercentage === 40} onChange={BonusHandler}></input>
									40 %
								</label>
								<label>
									<input type="checkbox" id="Bonus30" checked={BonusPercentage === 30} onChange={BonusHandler}></input>
									30 %
								</label>
								<label>
									<input type="checkbox" id="Bonus20" checked={BonusPercentage === 20} onChange={BonusHandler}></input>
									20 %
								</label>
								<label>
									<input type="checkbox" id="Bonus10" checked={BonusPercentage === 10} onChange={BonusHandler}></input>
									10 %
								</label>
								<label>
									<input type="checkbox" id="Bonus5" checked={BonusPercentage === 5} onChange={BonusHandler}></input>
									5 %
								</label>
							</div>
						)}
						{checkboxEarlyPaymentDiscountState && (
							<div className={s.price__selector}>
								<div><b>Величина скидки при ранней оплате:</b></div>
								<label>
									<input type="checkbox" id="earlyPaymentDiscount10" checked={earlyPaymentDiscount === 10} onChange={earlyPaymentDiscountHandler}></input>
									10 %
								</label>
								<label>
									<input type="checkbox" id="earlyPaymentDiscount5" checked={earlyPaymentDiscount === 5} onChange={earlyPaymentDiscountHandler}></input>
									5 %
								</label>
							</div>
						)}
						{checkboxEarlyPaymentBonusState && (
							<div className={s.price__selector}>
								<div><b>Величина суммы бонусных баллов при ранней оплате:</b></div>
								<label>
									<input type="checkbox" id="earlyPaymentBonus15" checked={earlyPaymentBonus === 15} onChange={earlyPaymentBonusHandler}></input>
									15 %
								</label>
								<label>
									<input type="checkbox" id="earlyPaymentBonus10" checked={earlyPaymentBonus === 10} onChange={earlyPaymentBonusHandler}></input>
									10 %
								</label>
								<label>
									<input type="checkbox" id="earlyPaymentBonus5" checked={earlyPaymentBonus === 5} onChange={earlyPaymentBonusHandler}></input>
									5 %
								</label>
							</div>
						)}
					</div>
					{checkboxNoDiscountState === true && (
						<div className={s.price__programDataSection}>
							<h4>Полная оплата</h4>
							<div className={s.price__selectors}>
								<div className={s.price__selector}>
									<div><b>Дата первого платежа:</b></div>
									<input type="date" value={singleInstallmentDateNoDiscount} onChange={singleInstallmentDateNoDiscountChangeHandler} />
								</div>
								{maxInstallments > 1 && <div className={s.price__selector}>
									<div><b>Ежемесячные платежи:</b></div>
									{maxInstallments > 8 && <label>
										<input type="checkbox" id="installmentPeriodNoDiscount9" checked={installmentPeriodNoDiscount === 9} onChange={installmentPeriodNoDiscountHandler}></input>
										9 платежей
									</label>}
									{maxInstallments > 5 && <label>
										<input type="checkbox" id="installmentPeriodNoDiscount6" checked={installmentPeriodNoDiscount === 6} onChange={installmentPeriodNoDiscountHandler}></input>
										6 платежей
									</label>}
									{maxInstallments > 3 && <label>
										<input type="checkbox" id="installmentPeriodNoDiscount4" checked={installmentPeriodNoDiscount === 4} onChange={installmentPeriodNoDiscountHandler}></input>
										4 платежа
									</label>}
									{maxInstallments > 2 && <label>
										<input type="checkbox" id="installmentPeriodNoDiscount3" checked={installmentPeriodNoDiscount === 3} onChange={installmentPeriodNoDiscountHandler}></input>
										3 платежа
									</label>}
									<label>
										<input type="checkbox" id="installmentPeriodNoDiscount2" checked={installmentPeriodNoDiscount === 2} onChange={installmentPeriodNoDiscountHandler}></input>
										2 платежа
									</label>
								</div>}
							</div>
							<div className={s.price__programDataElements}>
								{programPriceGroup4to12 !== 0 && (currentFormat === "group4to12" || currentFormat === "all") && (
									<div className={s.price__programDataElement}>
										<div>В&nbsp;группе из&nbsp;4–12&nbsp;учеников</div>
										<div>
											{paymentData(programPriceGroup4to12, installmentPeriodNoDiscount, 1, false, undefined, undefined, false, singleInstallmentDateNoDiscount)}
											{programsWithInstallment.includes(props.programId) && (
												<>
													<div className={s.price__divider}></div>
													<div>Для&nbsp;поступающих в&nbsp;школу: вступительный взнос в размере <span className={s.price__fontMedium}>{numberWithSeparators(installmentPrice)}&nbsp;&#8381;</span></div>
												</>
											)}
										</div>
									</div>
								)}
								{programPriceGroup3 !== 0 && (currentFormat === "group3" || currentFormat === "all") &&
									<div className={s.price__programDataElement}>
										<div>В&nbsp;группе из&nbsp;3&nbsp;учеников</div>
										<div>
											{paymentData(programPriceGroup3, installmentPeriodNoDiscount, 1, false, undefined, undefined, false, singleInstallmentDateNoDiscount)}
											{programsWithInstallment.includes(props.programId) && (
												<>
													<div className={s.price__divider}></div>
													<div>Для&nbsp;поступающих в&nbsp;школу: вступительный взнос в размере <span className={s.price__fontMedium}>{numberWithSeparators(installmentPrice)}&nbsp;&#8381;</span></div>
												</>
											)}
										</div>
									</div>
								}
								{programPriceGroup2 !== 0 && (currentFormat === "group2" || currentFormat === "all") &&
									<div className={s.price__programDataElement}>
										<div>В&nbsp;группе из&nbsp;2&nbsp;учеников</div>
										{paymentData(programPriceGroup2, installmentPeriodNoDiscount, 1, false, undefined, undefined, false, singleInstallmentDateNoDiscount)}
									</div>
								}
								{programPriceIndividual !== 0 && (currentFormat === "individual" || currentFormat === "all") &&
									<div className={s.price__programDataElement}>
										<div>Индивидуально</div>
										{paymentData(programPriceIndividual, installmentPeriodNoDiscount, 1, false, undefined, undefined, false, singleInstallmentDateNoDiscount)}
									</div>
								}
							</div>
						</div>
					)}
					{checkboxDiscountState === true && (
						<div className={s.price__programDataSection}>
							<h4>Оплата со скидкой {discountPercentage} %</h4>
							<div className={s.price__selector}>
								<div><b>Дата первого платежа:</b></div>
								<input type="date" value={singleInstallmentDateDiscount} onChange={singleInstallmentDateDiscountChangeHandler} />
							</div>
							{maxInstallments > 1 && <div className={s.price__selector}>
								<div><b>Ежемесячные платежи:</b></div>
								{maxInstallments > 8 && <label>
									<input type="checkbox" id="installmentPeriodDiscount9" checked={installmentPeriodDiscount === 9} onChange={installmentPeriodDiscountHandler}></input>
									9 платежей
								</label>}
								{maxInstallments > 5 && <label>
									<input type="checkbox" id="installmentPeriodDiscount6" checked={installmentPeriodDiscount === 6} onChange={installmentPeriodDiscountHandler}></input>
									6 платежей
								</label>}
								{maxInstallments > 3 && <label>
									<input type="checkbox" id="installmentPeriodDiscount4" checked={installmentPeriodDiscount === 4} onChange={installmentPeriodDiscountHandler}></input>
									4 платежа
								</label>}
								{maxInstallments > 2 && <label>
									<input type="checkbox" id="installmentPeriodDiscount3" checked={installmentPeriodDiscount === 3} onChange={installmentPeriodDiscountHandler}></input>
									3 платежа
								</label>}
								<label>
									<input type="checkbox" id="installmentPeriodDiscount2" checked={installmentPeriodDiscount === 2} onChange={installmentPeriodDiscountHandler}></input>
									2 платежа
								</label>
							</div>}
							<div className={s.price__programDataElements}>
								{programPriceGroup4to12 !== 0 && (currentFormat === "group4to12" || currentFormat === "all") && (
									<div className={s.price__programDataElement}>
										<div>В&nbsp;группе из&nbsp;4–12&nbsp;учеников</div>
										<div>
											{paymentData(programPriceGroup4to12, installmentPeriodDiscount, (1 - discountPercentage / 100), false, programPriceGroup4to12Education, programPriceGroup4to12Care, false, singleInstallmentDateDiscount)}
											{programsWithInstallment.includes(props.programId) && (
												<>
													<div className={s.price__divider}></div>
													<div>Для&nbsp;поступающих в&nbsp;школу: вступительный взнос в размере <span className={s.price__fontMedium}>{numberWithSeparators(installmentPrice)}&nbsp;&#8381;</span></div>
												</>
											)}
										</div>
									</div>
								)}
								{programPriceGroup3 !== 0 && (currentFormat === "group3" || currentFormat === "all") &&
									<div className={s.price__programDataElement}>
										<div>В&nbsp;группе из&nbsp;3&nbsp;учеников</div>
										<div>
											{paymentData(programPriceGroup3, installmentPeriodDiscount, (1 - discountPercentage / 100), false, undefined, undefined, false, singleInstallmentDateDiscount)}
											{programsWithInstallment.includes(props.programId) && (
												<>
													<div className={s.price__divider}></div>
													<div>Для&nbsp;поступающих в&nbsp;школу: вступительный взнос в размере <span className={s.price__fontMedium}>{numberWithSeparators(installmentPrice)}&nbsp;&#8381;</span></div>
												</>
											)}
										</div>
									</div>
								}
								{programPriceGroup2 !== 0 && (currentFormat === "group2" || currentFormat === "all") &&
									<div className={s.price__programDataElement}>
										<div>В&nbsp;группе из&nbsp;2&nbsp;учеников</div>
										{paymentData(programPriceGroup2, installmentPeriodDiscount, (1 - discountPercentage / 100), false, undefined, undefined, false, singleInstallmentDateDiscount)}
									</div>
								}
								{programPriceIndividual !== 0 && (currentFormat === "individual" || currentFormat === "all") &&
									<div className={s.price__programDataElement}>
										<div>Индивидуально</div>
										{paymentData(programPriceIndividual, installmentPeriodDiscount, (1 - discountPercentage / 100), false, undefined, undefined, false, singleInstallmentDateDiscount)}
									</div>
								}
							</div>
						</div>
					)}
					{checkboxBonusState === true && (
						<div className={s.price__programDataSection}>
							<h4>Оплата с начислением бонусных баллов в размере {BonusPercentage} %</h4>
							<div className={s.price__selector}>
								<div><b>Дата первого платежа:</b></div>
								<input type="date" value={singleInstallmentDateBonus} onChange={singleInstallmentDateBonusChangeHandler} />
							</div>
							{maxInstallments > 1 && <div className={s.price__selector}>
								<div><b>Ежемесячные платежи:</b></div>
								{maxInstallments > 8 && <label>
									<input type="checkbox" id="installmentPeriodBonus9" checked={installmentPeriodBonus === 9} onChange={installmentPeriodBonusHandler}></input>
									9 платежей
								</label>}
								{maxInstallments > 5 && <label>
									<input type="checkbox" id="installmentPeriodBonus6" checked={installmentPeriodBonus === 6} onChange={installmentPeriodBonusHandler}></input>
									6 платежей
								</label>}
								{maxInstallments > 3 && <label>
									<input type="checkbox" id="installmentPeriodBonus4" checked={installmentPeriodBonus === 4} onChange={installmentPeriodBonusHandler}></input>
									4 платежа
								</label>}
								{maxInstallments > 2 && <label>
									<input type="checkbox" id="installmentPeriodBonus3" checked={installmentPeriodBonus === 3} onChange={installmentPeriodBonusHandler}></input>
									3 платежа
								</label>}
								<label>
									<input type="checkbox" id="installmentPeriodBonus2" checked={installmentPeriodBonus === 2} onChange={installmentPeriodBonusHandler}></input>
									2 платежа
								</label>
							</div>}
							<div className={s.price__programDataElements}>
								{programPriceGroup4to12 !== 0 && (currentFormat === "group4to12" || currentFormat === "all") && (
									<div className={s.price__programDataElement}>
										<div>В&nbsp;группе из&nbsp;4–12&nbsp;учеников</div>
										<div>
											{paymentData(programPriceGroup4to12, installmentPeriodBonus, (1 - BonusPercentage / 100), false, undefined, undefined, true, singleInstallmentDateBonus)}
											{programsWithInstallment.includes(props.programId) && (
												<>
													<div className={s.price__divider}></div>
													<div>Для&nbsp;поступающих в&nbsp;школу: вступительный взнос в размере <span className={s.price__fontMedium}>{numberWithSeparators(installmentPrice)}&nbsp;&#8381;</span></div>
												</>
											)}
										</div>
									</div>
								)}
								{programPriceGroup3 !== 0 && (currentFormat === "group3" || currentFormat === "all") &&
									<div className={s.price__programDataElement}>
										<div>В&nbsp;группе из&nbsp;3&nbsp;учеников</div>
										<div>
											{paymentData(programPriceGroup3, installmentPeriodBonus, (1 - BonusPercentage / 100), false, 0, 0, true, singleInstallmentDateBonus)}
											{programsWithInstallment.includes(props.programId) && (
												<>
													<div className={s.price__divider}></div>
													<div>Для&nbsp;поступающих в&nbsp;школу: вступительный взнос в размере <span className={s.price__fontMedium}>{numberWithSeparators(installmentPrice)}&nbsp;&#8381;</span></div>
												</>
											)}
										</div>
									</div>
								}
								{programPriceGroup2 !== 0 && (currentFormat === "group2" || currentFormat === "all") &&
									<div className={s.price__programDataElement}>
										<div>В&nbsp;группе из&nbsp;2&nbsp;учеников</div>
										{paymentData(programPriceGroup2, installmentPeriodBonus, (1 - BonusPercentage / 100), false, 0, 0, true, singleInstallmentDateBonus)}
									</div>
								}
								{programPriceIndividual !== 0 && (currentFormat === "individual" || currentFormat === "all") &&
									<div className={s.price__programDataElement}>
										<div>Индивидуально</div>
										{paymentData(programPriceIndividual, installmentPeriodBonus, (1 - BonusPercentage / 100), false, 0, 0, true, singleInstallmentDateBonus)}
									</div>
								}
							</div>
						</div>
					)}
					{currentFormat !== "individual" && (programPriceGroup4to12 !== 0 || programPriceGroup3 !== 0 || programPriceGroup2 !== 0) && checkboxEarlyPaymentDiscountState === true && (
						<div className={s.price__programDataSection}>
							<h4>{`Ранняя оплата ${earlyPaymentDiscountPeriod} со скидкой ${earlyPaymentDiscount} %`}</h4>
							<div className={s.price__selector}>
								<div><b>Дата первого платежа:</b></div>
								<input type="date" value={singleInstallmentDateEarlyPaymentDiscount} onChange={singleInstallmentDateEarlyPaymentDiscountChangeHandler} />
							</div>
							<div className={s.price__selector}>
								<div><b>Ежемесячные платежи:</b></div>
								<label>
									<input type="checkbox" id="earlyPaymentDiscountInstallmentPeriod9" checked={earlyPaymentDiscountInstallmentPeriod === 9} onChange={earlyPaymentDiscountInstallmentPeriodHandler}></input>
									9 платежей
								</label>
								<label>
									<input type="checkbox" id="earlyPaymentDiscountInstallmentPeriod6" checked={earlyPaymentDiscountInstallmentPeriod === 6} onChange={earlyPaymentDiscountInstallmentPeriodHandler}></input>
									6 платежей
								</label>
								<label>
									<input type="checkbox" id="earlyPaymentDiscountInstallmentPeriod4" checked={earlyPaymentDiscountInstallmentPeriod === 4} onChange={earlyPaymentDiscountInstallmentPeriodHandler}></input>
									4 платежа
								</label>
								<label>
									<input type="checkbox" id="earlyPaymentDiscountInstallmentPeriod3" checked={earlyPaymentDiscountInstallmentPeriod === 3} onChange={earlyPaymentDiscountInstallmentPeriodHandler}></input>
									3 платежа
								</label>
							</div>
							<div className={s.price__programDataElements}>
								{programPriceGroup4to12 !== 0 && (currentFormat === "group4to12" || currentFormat === "all") && (
									<div className={s.price__programDataElement}>
										<div>В&nbsp;группе из&nbsp;4–12&nbsp;учеников</div>
										<div>
											{paymentData(programPriceGroup4to12, earlyPaymentDiscountInstallmentPeriod, 1 - (earlyPaymentDiscount / 100), true, undefined, undefined, false, singleInstallmentDateEarlyPaymentDiscount)}
											{programsWithInstallment.includes(props.programId) && (
												<>
													<div className={s.price__divider}></div>
													<div>Для&nbsp;поступающих в&nbsp;школу: вступительный взнос в размере <span className={s.price__fontMedium}>{numberWithSeparators(installmentPrice)}&nbsp;&#8381;</span></div>
												</>
											)}
										</div>
									</div>
								)}
								{programPriceGroup3 !== 0 && (currentFormat === "group3" || currentFormat === "all") &&
									<div className={s.price__programDataElement}>
										<div>В&nbsp;группе из&nbsp;3&nbsp;учеников</div>
										<div>
											{paymentData(programPriceGroup3, earlyPaymentDiscountInstallmentPeriod, 1 - (earlyPaymentDiscount / 100), true, undefined, undefined, false, singleInstallmentDateEarlyPaymentDiscount)}
											{programsWithInstallment.includes(props.programId) && (
												<>
													<div className={s.price__divider}></div>
													<div>Для&nbsp;поступающих в&nbsp;школу: вступительный взнос в размере <span className={s.price__fontMedium}>{numberWithSeparators(installmentPrice)}&nbsp;&#8381;</span></div>
												</>
											)}
										</div>
									</div>
								}
								{programPriceGroup2 !== 0 && (currentFormat === "group2" || currentFormat === "all") &&
									<div className={s.price__programDataElement}>
										<div>В&nbsp;группе из&nbsp;2&nbsp;учеников</div>
										{paymentData(programPriceGroup2, earlyPaymentDiscountInstallmentPeriod, 1 - (earlyPaymentDiscount / 100), true, undefined, undefined, false, singleInstallmentDateEarlyPaymentDiscount)}
									</div>
								}
							</div>
						</div>
					)}
					{currentFormat !== "individual" && (programPriceGroup4to12 !== 0 || programPriceGroup3 !== 0 || programPriceGroup2 !== 0) && checkboxEarlyPaymentBonusState === true && (
						<div className={s.price__programDataSection}>
							<h4>{`Ранняя оплата ${earlyPaymentBonusPeriod} с начислением бонусных баллов в размере ${earlyPaymentBonus} %`}</h4>
							<div className={s.price__selector}>
								<div><b>Дата первого платежа:</b></div>
								<input type="date" value={singleInstallmentDateEarlyPaymentBonus} onChange={singleInstallmentDateEarlyPaymentBonusChangeHandler} />
							</div>
							<div className={s.price__selector}>
								<div><b>Ежемесячные платежи:</b></div>
								<label>
									<input type="checkbox" id="earlyPaymentBonusInstallmentPeriod9" checked={earlyPaymentBonusInstallmentPeriod === 9} onChange={earlyPaymentBonusInstallmentPeriodHandler}></input>
									9 месяцев
								</label>
								<label>
									<input type="checkbox" id="earlyPaymentBonusInstallmentPeriod6" checked={earlyPaymentBonusInstallmentPeriod === 6} onChange={earlyPaymentBonusInstallmentPeriodHandler}></input>
									6 месяцев
								</label>
								<label>
									<input type="checkbox" id="earlyPaymentBonusInstallmentPeriod4" checked={earlyPaymentBonusInstallmentPeriod === 4} onChange={earlyPaymentBonusInstallmentPeriodHandler}></input>
									4 месяца
								</label>
								<label>
									<input type="checkbox" id="earlyPaymentBonusInstallmentPeriod3" checked={earlyPaymentBonusInstallmentPeriod === 3} onChange={earlyPaymentBonusInstallmentPeriodHandler}></input>
									3 месяца
								</label>
							</div>
							<div className={s.price__programDataElements}>
								{programPriceGroup4to12 !== 0 && (currentFormat === "group4to12" || currentFormat === "all") && (
									<div className={s.price__programDataElement}>
										<div>В&nbsp;группе из&nbsp;4–12&nbsp;учеников</div>
										<div>
											{paymentData(programPriceGroup4to12, earlyPaymentBonusInstallmentPeriod, 1 - (earlyPaymentBonus / 100), true, undefined, undefined, true, singleInstallmentDateEarlyPaymentBonus)}
											{programsWithInstallment.includes(props.programId) && (
												<>
													<div className={s.price__divider}></div>
													<div>Для&nbsp;поступающих в&nbsp;школу: вступительный взнос в размере <span className={s.price__fontMedium}>{numberWithSeparators(installmentPrice)}&nbsp;&#8381;</span></div>
												</>
											)}
										</div>
									</div>
								)}
								{programPriceGroup3 !== 0 && (currentFormat === "group3" || currentFormat === "all") &&
									<div className={s.price__programDataElement}>
										<div>В&nbsp;группе из&nbsp;3&nbsp;учеников</div>
										<div>
											{paymentData(programPriceGroup3, earlyPaymentBonusInstallmentPeriod, 1 - (earlyPaymentBonus / 100), true, 0, 0, true, singleInstallmentDateEarlyPaymentBonus)}
											{programsWithInstallment.includes(props.programId) && (
												<>
													<div className={s.price__divider}></div>
													<div>Для&nbsp;поступающих в&nbsp;школу: вступительный взнос в размере <span className={s.price__fontMedium}>{numberWithSeparators(installmentPrice)}&nbsp;&#8381;</span></div>
												</>
											)}
										</div>
									</div>
								}
								{programPriceGroup2 !== 0 && (currentFormat === "group2" || currentFormat === "all") &&
									<div className={s.price__programDataElement}>
										<div>В&nbsp;группе из&nbsp;2&nbsp;учеников</div>
										{paymentData(programPriceGroup2, earlyPaymentBonusInstallmentPeriod, 1 - (earlyPaymentBonus / 100), true, 0, 0, true, singleInstallmentDateEarlyPaymentBonus)}
									</div>
								}
							</div>
						</div>
					)}
				</div>
			</div>
		);
	} else {
		return (
			<></>
		);
	}
}

const Price: React.FC<{}> = () => {
	const [currentCourseId, setCurrentCourseId] = useState<number>(0);
	const currentCourseYear = 1;

	const currentCourseHandler = (event: ChangeEvent<HTMLSelectElement>) => {
		setCurrentCourseId(+event.target.value);
		setCurrentProgramId(0);
	}

	const [currentProgramList, setCurrentProgramList] = useState<ProgramType[]>([]);

	useEffect(() => {
		let selectedCourse: CourseType[] = courses.filter((el) => el.id === currentCourseId);
		if (selectedCourse.length > 0) {
			const programsInCourse = programs.filter((el) => selectedCourse[0].programs.includes(el.id));
			setCurrentProgramList(programsInCourse);
		}
	}, [currentCourseId]);

	const [currentProgramId, setCurrentProgramId] = useState<number>(0);

	const currentProgramHandler = (event: ChangeEvent<HTMLSelectElement>) => {
		setCurrentProgramId(+event.target.value);
	}

	const coursesFilteredByYear = courses
		.filter((el) => el.years.includes(+currentCourseYear))
		.sort((a, b) => a.code.localeCompare(b.code));

	return (
		<div className={s.price}>
			<Helmet>
				<title>Расчёт стоимости обучения | TheWiz — образовательная онлайн-платформа</title>
				<meta name="description" content="Получите детальный расчет стоимости и график платежей по любой программе с учётом всех действующих скидок" />
			</Helmet>
			<div className={s.price__contentHero}>
				<h2>Расчёт стоимости обучения</h2>
				<h3>2024/25 учебный год</h3>
			</div>
			<div className={s.price__selectors}>
				<div className={s.price__selector}>
					<div>Курс:</div>
					<select value={currentCourseId} onChange={currentCourseHandler}>
						<option value={0}>Выберите курс...</option>
						{coursesFilteredByYear.map((el) => {
							return (<option value={el.id}>{el.code} — {el.alias}</option>);
						})}
					</select>
				</div>
				<div className={s.price__selector}>
					<div>Программа:</div>
					<select value={currentProgramId} onChange={currentProgramHandler}>
						<option value={0}>Выберите программу...</option>
						{currentProgramList.map((el) => {
							return <option value={el.id}>{el.alias}</option>
						})}
					</select>
				</div>
			</div>
			{currentProgramId !== 0 && <PriceDetails programId={currentProgramId} />}
		</div>
	);
}

export default Price;