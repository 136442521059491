import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import AdditionInMind from './AdditionInMind/AdditionInMind';
import DivisionInMind from './DivisionInMind/DivisionInMind';
import s from './Math.module.css';
import MultiplicationTable from "./MultiplicationTable/MultiplicationTable";
import SubtractionInMind from './SubtractionInMind/SubtractionInMind';
import NotFound from '../../../Core/NotFound/NotFound';

const MathContent: React.FC<{}> = () => {
	return (
		<div className={s.math__content}>
			<div className={s.math__contentSections}>
				<div className={s.math__contentSectionsContainer}>
					<div className={s.math__contentSection}>
						<h3>Таблица умножения</h3>
						<a href="/play/math/multiplication-table">Перейти</a>
					</div>
					<div className={s.math__contentSection}>
						<h3>Сложение в&nbsp;уме</h3>
						<a href="/play/math/addition-in-mind">Перейти</a>
					</div>
					<div className={s.math__contentSection}>
						<h3>Вычитание в&nbsp;уме</h3>
						<a href="/play/math/subtraction-in-mind">Перейти</a>
					</div>
					<div className={s.math__contentSection}>
						<h3>Деление в&nbsp;уме</h3>
						<a href="/play/math/division-in-mind">Перейти</a>
					</div>
				</div>
			</div>
		</div>
	);
}

const Math: React.FC<{}> = () => {
	return (
		<div className={s.math}>
			<Helmet>
				<title>Интерактивные тренажёры —  математика | TheWiz — образовательная онлайн-платформа</title>
				<meta name="description" content="Интерактивные тренажёры с искусственным интеллектом позволяют детям и взрослым оттачивать свои навыки" />
			</Helmet>
			<h2 className={s.math__contentHero}>Математика</h2>
			<Routes>
				<Route path="/" Component={MathContent} />
				<Route path="/multiplication-table" Component={MultiplicationTable} />
				<Route path="/addition-in-mind" Component={AdditionInMind} />
				<Route path="/subtraction-in-mind" Component={SubtractionInMind} />
				<Route path="/division-in-mind" Component={DivisionInMind} />
				<Route path="*" Component={NotFound} />
			</Routes>
		</div>
	);
}

export default Math;