import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import s from './English.module.css';
import ObjectPronouns from './ObjectPronouns/ObjectPronouns';
import NotFound from '../../../Core/NotFound/NotFound';

const EnglishContent: React.FC<{}> = () => {
	return (
		<div className={s.english__content}>
			<div className={s.english__contentSections}>
				<div className={s.english__contentSectionsContainer}>
					<div className={s.english__contentSection}>
						<h3>Object Pronouns</h3>
						<a href="/play/english/object-pronouns">Перейти</a>
					</div>
				</div>
			</div>
		</div>
	);
}

const English: React.FC<{}> = () => {
	return (
		<div className={s.english}>
			<Helmet>
				<title>Интерактивные тренажёры —  английский язык | TheWiz — образовательная онлайн-платформа</title>
				<meta name="description" content="Интерактивные тренажёры с искусственным интеллектом позволяют детям и взрослым оттачивать свои навыки" />
			</Helmet>
			<h2 className={s.english__contentHero}>English</h2>
			<Routes>
				<Route path="/" Component={EnglishContent} />
				<Route path="/object-pronouns" Component={ObjectPronouns} />
				<Route path="*" Component={NotFound} />
			</Routes>
		</div>
	);
}

export default English;