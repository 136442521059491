export const numberWithSeparators = (number: number) => {
	if (number.toString().length > 4) {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "\u00A0");
	} else {
		return number.toString();
	}
}

export const addRubles = (text: string) => {
	if (text.trim().length > 0) {
		return text + '\u00A0\u20BD';
	} else {
		return text;
	}
}

export const processDateFromInput = (date: string) => {
	let parts = date.split("-");
	let formattedDate = parts[2] + "." + parts[1] + "." + parts[0];
	return formattedDate;
}

export const paymentsText = (payments: number) => {
	if (payments === 1) {
		return payments.toString() + ' платёж';
	} else if (payments < 5) {
		return payments.toString() + ' платежа';
	} else {
		return payments.toString() + ' платежей';
	}
}

export const getMonthFromDateInput = (date: string) => {
	let parts = date.split("-");
	return parts[1];
}

export const getYearFromDateInput = (date: string) => {
	let parts = date.split("-");
	return parts[0];
}