import s from './NotFound.module.css';

const NotFound: React.FC<{}> = () => {
	return(
		<div className={s.notFound}>
			<h4 className={s.notFound__heading}>Такой страницы нет</h4>
			<div>Можно вернуться на&nbsp;<a href="/">главную страницу</a></div>
		</div>
	);
}

export default NotFound;