import s from './Play.module.css';
import Math from './Math/Math';
import { Route, Routes } from 'react-router-dom';
import English from './English/English';
import Breadcrumbs from '../../Core/Breadcrumbs/Breadcrumbs';
import { Helmet } from 'react-helmet';
import NotFound from '../../Core/NotFound/NotFound';

const PlayContent: React.FC<{}> = () => {
	return (
		<div className={s.play__content}>
			<div className={s.play__contentHero}>
				<h2>Playground</h2>
				<div>Интерактивные тренажеры</div>
			</div>
			<div className={s.play__contentSections}>
				<div className={s.play__contentSectionsTitle}>
					<h3>Выбирай предмет</h3>
				</div>
				<div className={s.play__contentSectionsContainer}>
					<div className={s.play__contentSection + ' ' + s.play__contentSection_math}>
						<h3>Математика</h3>
						<a href="/play/math">Перейти</a>
					</div>
					<div className={s.play__contentSection + ' ' + s.play__contentSection_inactive}>
						<h3>Русский язык</h3>
						<div>Скоро появится</div>
					</div>
					<div className={s.play__contentSection + ' ' + s.play__contentSection_english}>
						<h3>Английский язык</h3>
						<a href="/play/english">Перейти</a>
					</div>
				</div>
			</div>
			<div className={s.play__contentSections}>
				<div className={s.play__contentSectionsTitle}>
					<h3>Или сразу переходи к популярным тренажерам</h3>
				</div>
				<div className={s.play__contentSectionsContainer}>
					<div className={s.play__contentSection + ' ' + s.play__contentSection_math}>
						<h3>Математика: таблица умножения</h3>
						<a href="/play/math/multiplication-table">Перейти</a>
					</div>
					<div className={s.play__contentSection + ' ' + s.play__contentSection_math}>
						<h3>Математика: деление в&nbsp;уме</h3>
						<a href="/play/math/division-in-mind">Перейти</a>
					</div>
					<div className={s.play__contentSection + ' ' + s.play__contentSection_math}>
						<h3>Математика: сложение в&nbsp;уме</h3>
						<a href="/play/math/addition-in-mind">Перейти</a>
					</div>
					<div className={s.play__contentSection + ' ' + s.play__contentSection_math}>
						<h3>Математика: вычитание в&nbsp;уме</h3>
						<a href="/play/math/subtraction-in-mind">Перейти</a>
					</div>
					<div className={s.play__contentSection + ' ' + s.play__contentSection_english}>
						<h3>Английский язык: Object Pronouns</h3>
						<a href="/play/english/object-pronouns">Перейти</a>
					</div>
				</div>
			</div>
		</div>
	);
}

const Play: React.FC<{}> = () => {
	return (
		<div className={s.play}>
			<Helmet>
				<title>Интерактивные тренажёры | TheWiz — образовательная онлайн-платформа</title>
				<meta name="description" content="Интерактивные тренажёры с искусственным интеллектом позволяют детям и взрослым оттачивать свои навыки" />
			</Helmet>
			<Routes>
				<Route path="/" Component={PlayContent} />
				<Route path="/math/*" Component={Math} />
				<Route path="/english/*" Component={English} />
				<Route path="*" Component={NotFound} />
			</Routes>
			<Breadcrumbs />
		</div>
	);
}

export default Play;