import React from 'react';
import './App.css';
import Main from './components/Main/Main';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import NotFound from './components/Core/NotFound/NotFound';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const App: React.FC<{}> = () => {
	return (
		<div>
			<Helmet>
				<meta charSet="utf-8" />
				<title>TheWiz — образовательная онлайн-платформа</title>
				<meta name="description" content="TheWiz — образовательная онлайн-платформа" />
			</Helmet>
			<Header />
			<BrowserRouter>
				<Routes>
					<Route path="/*" Component={Main} />
					<Route path="*" Component={NotFound} />
				</Routes>
			</BrowserRouter>
			<Footer />
		</div>
	);
}

export default App;
