import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import Breadcrumbs from '../../Core/Breadcrumbs/Breadcrumbs';
import s from './Crm.module.css';
import Price from './Price/Price';
import ProgramList from './ProgramList/ProgramList';
import NotFound from '../../Core/NotFound/NotFound';
import { useState } from 'react';

const CrmContent: React.FC<{}> = () => {
	return (
		<div className={s.crm__content}>
			<div className={s.crm__contentHero}>
				<h2>CRM TheWiz</h2>
				<div>Система управления обучением</div>
			</div>
			<div className={s.crm__contentSections}>
				<div className={s.crm__contentSectionsContainer}>
					<div className={s.crm__contentSection}>
						<h3>Перечень программ</h3>
						<a href="/crm/program-list">Перейти</a>
					</div>
					<div className={s.crm__contentSection}>
						<h3>Расчёт стоимости обучения</h3>
						<a href="/crm/price">Перейти</a>
					</div>
				</div>
			</div>
		</div>
	);
}

const CrmLogin: React.FC<{ setLoggedIn: (loggedIn: boolean) => void }> = ({ setLoggedIn }) => {
	const [codeValue, setCodeValue] = useState<string>('');

	function codeValueChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
		setCodeValue(e.target.value);
	}

	function validateLogin(e: React.MouseEvent<HTMLInputElement>) {
		e.preventDefault();
		if ('acadiaschoolcrm2024' === codeValue) {
			sessionStorage.setItem('thewizcrmloggedin', 'true');
			
			let date = new Date();
			date.setTime(date.getTime() + (8*60*60*1000));
			let expires = "; expires=" + date.toUTCString();

			document.cookie = "thewizcrmloggedin=true" + expires + "; path=/";

			setLoggedIn(true);
		} else {
			setCodeValue('');
		}
	}

	return (
		<div className={s.crm}>
			<h2>Вход в CRM TheWiz</h2>
			<div className={s.crm__login}>
				<span>Код доступа: </span>
				<input type="password" autoComplete="new-password" value={codeValue} onChange={codeValueChangeHandler} />
				<input type="button" value="Войти" onClick={validateLogin} />
			</div>
		</div>
	);

}

const Crm: React.FC<{}> = () => {
	const [loggedIn, setLoggedIn] = useState<boolean>(sessionStorage.getItem('thewizcrmloggedin') === 'true' || document.cookie.indexOf('thewizcrmloggedin=true') !== -1);

	if (!loggedIn) {
		return (
			<div className={s.crm}>
				<CrmLogin setLoggedIn={setLoggedIn} />
			</div>
		);
	}

	return (
		<div className={s.crm}>
			<Helmet>
				<title>Система управления обучением | TheWiz — образовательная онлайн-платформа</title>
				<meta name="description" content="Система управления обучением TheWiz позволяет управлять онлайн- или офлайн-школой" />
			</Helmet>
			<Routes>
				<Route path="/" Component={CrmContent} />
				<Route path="/price" Component={Price} />
				<Route path="/program-list" Component={ProgramList} />
				<Route path="*" Component={NotFound} />
			</Routes>
			<Breadcrumbs />
		</div>
	)
}

export default Crm;