import { ChangeEvent, KeyboardEvent, MutableRefObject, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import s from './DivisionInMind.module.css';

const ClosedEye: React.FC<{}> = () => {
	return (
		<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M21.0006 12.0007C19.2536 15.5766 15.8779 18 12 18M12 18C8.12204 18 4.7463 15.5766 2.99977 12.0002M12 18L12 21M19.4218 14.4218L21.4999 16.5M16.2304 16.9687L17.5 19.5M4.57812 14.4218L2.5 16.5M7.76953 16.9687L6.5 19.5" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
		</svg>
	);
}

const OpenEye: React.FC<{}> = () => {
	return (
		<svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M22 12.0002C20.2531 15.5764 15.8775 19 11.9998 19C8.12201 19 3.74646 15.5764 2 11.9998" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
			<path fill-rule="evenodd" clip-rule="evenodd" d="M22 12.0002C20.2531 8.42398 15.8782 5 12.0005 5C8.1227 5 3.74646 8.42314 2 11.9998" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
			<path d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
		</svg>
	);
}

const DivisionInMind: React.FC<{}> = () => {
	const getRandomInt = (max: number): number => {
		return Math.floor(Math.random() * max + 1);
	}

	const [difficultyLevel, setDifficultyLevel] = useState<string>("1");
	const [maxNumOne, setMaxNumOne] = useState<number>(9);
	const [maxNumTwo, setMaxNumTwo] = useState<number>(9);

	const changeDifficultyLevel = (level: string) => {
		if (level === "1" && difficultyLevel !== "1") {
			setDifficultyLevel(level);
			setMaxNumOne(10);
			setMaxNumTwo(10);
		}
		if (level === "2" && difficultyLevel !== "2") {
			setDifficultyLevel(level);
			setMaxNumOne(20);
			setMaxNumTwo(10);
		}
	}

	useEffect(() => {
		setNumOne(getRandomInt(maxNumOne));
		setNumTwo(getRandomInt(maxNumTwo));
		setCorrectAnswers(0);
		setCorrectAnswersPercentage(0);
		setIncorrectAnswers(0);
		setIncorrectAnswersPercentage(0);
		setTotalAnswers(0);
		setCurrentAnswer('');
		setCorrectAnswerLog([]);
		setCorrectAnswerLogVisibility(false);
		setErrorLog([]);
		setErrorLogVisibility(false);
		answerInputRef.current.focus();
	}, [maxNumOne, maxNumTwo]);

	const numOneInitial: number = getRandomInt(maxNumOne);
	const numTwoInitial: number = getRandomInt(maxNumTwo);

	const [numOne, setNumOne] = useState<number>(numOneInitial);
	const [numTwo, setNumTwo] = useState<number>(numTwoInitial);

	const [correctAnswers, setCorrectAnswers] = useState<number>(0);
	const [correctAnswersPercentage, setCorrectAnswersPercentage] = useState<number>(0);
	const [incorrectAnswers, setIncorrectAnswers] = useState<number>(0);
	const [incorrectAnswersPercentage, setIncorrectAnswersPercentage] = useState<number>(0);
	const [totalAnswers, setTotalAnswers] = useState<number>(0);

	const [currentAnswer, setCurrentAnswer] = useState<string>('');

	function updateCurrentAnswer(e: ChangeEvent<HTMLInputElement>) {
		setCurrentAnswer(e.target.value);
	}

	function inputOnChangeHandler(e: ChangeEvent<HTMLInputElement>) {
		if (e.currentTarget.value.length <= 2 && /^(\s*|\d+)$/.test(e.currentTarget.value)) {
			updateCurrentAnswer(e);
		}
	}

	const [lastAnswerCorrect, setLastAnswerCorrect] = useState<boolean>(false);
	const [lastAnswerIncorrect, setLastAnswerIncorrect] = useState<boolean>(false);

	function highlightCorrectAnswer() {
		setLastAnswerCorrect(true);
		setLastAnswerIncorrect(false);
	}

	function highlightIncorrectAnswer() {
		setLastAnswerIncorrect(true);
		setLastAnswerCorrect(false);
	}

	useEffect(() => {
		let resetAnswersHighlight = setTimeout(() => {
			setLastAnswerCorrect(false);
			setLastAnswerIncorrect(false);
		}, 3000);
		return () => clearTimeout(resetAnswersHighlight);
	}, [lastAnswerCorrect, lastAnswerIncorrect]);

	function checkAnswer() {
		if ('' !== currentAnswer) {
			setTotalAnswers(totalAnswers + 1)
			if (numOne === +currentAnswer) {
				setCorrectAnswers(correctAnswers + 1);
				highlightCorrectAnswer();
				let updatedCorrectAnswerLog: CorrectAnswerType[] = [
					{
						question: numOne * numTwo + ' : ' + numTwo,
						givenAnswer: +currentAnswer
					},
					...correctAnswerLog
				];
				setCorrectAnswerLog(updatedCorrectAnswerLog);
			} else {
				setIncorrectAnswers(incorrectAnswers + 1);
				highlightIncorrectAnswer();
				let updatedErrorLog: ErrorType[] = [
					{
						question: numOne * numTwo + ' : ' + numTwo,
						givenAnswer: +currentAnswer,
						correctAnswer: numOne
					},
					...errorLog
				];
				setErrorLog(updatedErrorLog);
			}
			setNumOne(getRandomInt(maxNumOne));
			setNumTwo(getRandomInt(maxNumTwo));
			setCurrentAnswer('');
		};
	}

	function buttonOnClickHandler() {
		checkAnswer();
	}

	function inputOnKeyUpHandler(e: KeyboardEvent<HTMLInputElement>) {
		if (e.key === "Enter") {
			checkAnswer();
		}
	}

	useEffect(() => {
		if (totalAnswers !== 0) {
			setCorrectAnswersPercentage(Math.ceil(correctAnswers / totalAnswers * 100));
			setIncorrectAnswersPercentage(Math.floor(incorrectAnswers / totalAnswers * 100));
		}
		answerInputRef.current.focus();
	}, [totalAnswers, correctAnswers, incorrectAnswers]);

	const [secondsPassed, setSecondsPassed] = useState<number>(0);

	useEffect(() => {
		const timer = setInterval(() => { setSecondsPassed(secondsPassed + 1) }, 1000);
		return () => clearInterval(timer);
	}, [secondsPassed]);

	useEffect(() => {
		setSecondsPassed(0);
	}, [difficultyLevel]);

	function formatTime(seconds: number) {
		const h = Math.floor(seconds / 3600);
		const m = Math.floor((seconds % 3600) / 60);
		const s = Math.round(seconds % 60);
		return [
			h,
			m > 9 ? m : (h ? '0' + m : m || '0'),
			s > 9 ? s : '0' + s
		].filter(Boolean).join(':');
	}

	type CorrectAnswerType = {
		question: string;
		givenAnswer: number;
	}

	const [correctAnswerLog, setCorrectAnswerLog] = useState<CorrectAnswerType[]>([]);

	const [correctAnswerLogVisibility, setCorrectAnswerLogVisibility] = useState<boolean>(false);

	const showCorrectAnswerLogEye = () => {
		if (correctAnswerLog.length > 0) {
			if (correctAnswerLogVisibility) {
				return <OpenEye />
			} else {
				return <ClosedEye />
			}
		}
	}

	type ErrorType = {
		question: string;
		givenAnswer: number;
		correctAnswer: number;
	}

	const [errorLog, setErrorLog] = useState<ErrorType[]>([]);

	const [errorLogVisibility, setErrorLogVisibility] = useState<boolean>(false);

	const showErrorLogEye = () => {
		if (errorLog.length > 0) {
			if (errorLogVisibility) {
				return <OpenEye />
			} else {
				return <ClosedEye />
			}
		}
	}

	const toggleErrorLogVisibility = () => {
		setErrorLogVisibility(!errorLogVisibility);
		answerInputRef.current.focus();
	}

	const toggleCorrectAnswerLogVisibility = () => {
		setCorrectAnswerLogVisibility(!correctAnswerLogVisibility);
		answerInputRef.current.focus();
	}

	const answerInputRef = useRef() as MutableRefObject<HTMLInputElement>;;

	return (
		<div className={s.divisionInMind}>
			<Helmet>
				<title>Интерактивный тренажёр — Деление в уме | TheWiz — образовательная онлайн-платформа</title>
				<meta name="description" content="Интерактивные тренажёры с искусственным интеллектом позволяют детям и взрослым оттачивать свои навыки" />
			</Helmet>

			<h3>Деление в уме</h3>
			<div className={s.divisionInMind__difficultyLevel}>
				<div>Текущий уровень сложности: {difficultyLevel}</div>
				<div>Изменить уровень сложности:</div>
				<button
					className={"1" === difficultyLevel ? s.divisionInMind__levelButton + ' ' + s.active : s.divisionInMind__levelButton}
					onClick={() => changeDifficultyLevel("1")}>
					1
				</button>
				<button
					className={"2" === difficultyLevel ? s.divisionInMind__levelButton + ' ' + s.active : s.divisionInMind__levelButton}
					onClick={() => changeDifficultyLevel("2")}>
					2
				</button>
			</div>
			<div className={s.divisionInMind__divider}></div>
			<div className={s.divisionInMind__taskElements}>
				<div className={s.divisionInMind__dividend}>{numOne * numTwo}</div>
				<div className={s.divisionInMind__divisionSign}>:</div>
				<div className={s.divisionInMind__divisor}>{numTwo}</div>
			</div>
			<div className={s.divisionInMind__answer}>
				<div>Частное:</div>
				<input
					className={s.divisionInMind__answerInput}
					value={currentAnswer}
					onChange={inputOnChangeHandler}
					onKeyUp={inputOnKeyUpHandler}
					autoFocus
					ref={answerInputRef}
				/>
			</div>
			<div>
				<button onClick={buttonOnClickHandler}>Ответить</button>
			</div>
			<div className={s.divisionInMind__divider}></div>
			<div className={s.divisionInMind__answerStatistics}>
				<div className={true === lastAnswerCorrect ? s.divisionInMind__correctAnswers + ' ' + s.active : s.divisionInMind__correctAnswers}>
					Правильных ответов: <span>{correctAnswers}, {correctAnswersPercentage.toFixed(0)}&nbsp;%</span>
					<span className={s.divisionInMind__eye} onClick={toggleCorrectAnswerLogVisibility}>{showCorrectAnswerLogEye()}</span>
				</div>
				<div className={true === lastAnswerIncorrect ? s.divisionInMind__incorrectAnswers + ' ' + s.active : s.divisionInMind__incorrectAnswers}>
					Неправильных ответов: <span>{incorrectAnswers}, {incorrectAnswersPercentage.toFixed(0)}&nbsp;%</span>
					<span className={s.divisionInMind__eye} onClick={toggleErrorLogVisibility}>{showErrorLogEye()}</span>
				</div>
				<div>Всего ответов: {correctAnswers + incorrectAnswers}</div>
			</div>
			<div>Прошло времени: {formatTime(secondsPassed)}</div>
			<div className={true === correctAnswerLogVisibility ? s.divisionInMind__log + ' ' + s.divisionInMind__log_visible : s.divisionInMind__log}>
				<h4>Правильные ответы</h4>
				<div className={s.divisionInMind__correctAnswersList}>
					<div>
						<div>Вопрос</div>
						<div>
							{correctAnswerLog.map((el) => <div>{el.question}</div>)}
						</div>
					</div>
					<div>
						<div>Ответ</div>
						<div>
							{correctAnswerLog.map((el) => <div><span className={s.divisionInMind__correctAnswerLogAnswer}>{el.givenAnswer}</span></div>)}
						</div>
					</div>
				</div>
			</div>
			<div className={true === errorLogVisibility ? s.divisionInMind__log + ' ' + s.divisionInMind__log_visible : s.divisionInMind__log}>
				<h4>Ошибки</h4>
				<div className={s.divisionInMind__errorList}>
					<div>
						<div>Вопрос</div>
						<div>
							{errorLog.map((el) => <div>{el.question}</div>)}
						</div>
					</div>
					<div>
						<div>Ответ</div>
						<div>
							{errorLog.map((el) => <div><span className={s.divisionInMind__errorLogAnswer}>{el.givenAnswer}</span></div>)}
						</div>
					</div>
					<div>
						<div>Правильно</div>
						<div>
							{errorLog.map((el) => <div>{el.correctAnswer}</div>)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DivisionInMind;