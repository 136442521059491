import s from './Header.module.css';

const HeaderContentInner: React.FC<{}> = () => {
	return (
		<>
			<div className={s.header__logo}>
				<img src="https://thewiz.ru/thewiz-270.png" alt="TheWiz" />
			</div>
			<div className={s.header__title}>TheWiz</div>
			<div className={s.header__subtitle}>Образовательная онлайн-платформа</div>
		</>
	);
}

const HeaderContentCrmLink: React.FC<{}> = () => {
	return(
		<a className={s.header__crmLink} href="/crm">Вход для сотрудников</a>
	);
}

const HeaderContent: React.FC<{}> = () => {
	if ("/" === window.location.pathname) {
		return (
			<div className={s.header__content}>
				<HeaderContentInner />
				<HeaderContentCrmLink />
			</div>
		);
	} else {
		return (
			<a href="/" className={s.header__content}>
				<HeaderContentInner />
			</a>
		);
	}
}

const Header: React.FC<{}> = () => {
	return (
		<header className={s.header}>
			<HeaderContent />
		</header>
	);
}

export default Header;