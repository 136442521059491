import Version from '../Version/Version';
import s from './Footer.module.css';

const Footer: React.FC<{}> = () => {
	return(
		<footer className={s.footer}>
			<div className={s.footer__title}>TheWiz&nbsp;&mdash; образовательная онлайн-платформа, 2019&ndash;2024</div>
			<div className={s.footer__version}><Version /></div>
		</footer>
	);
}

export default Footer;