import s from './CopyToClipboardTheWiz.module.css';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useState } from 'react';
import copyToClipboardLogo from './../../../assets/images/copy.svg';

const CopyToClipboardTheWiz: React.FC<{ text: string }> = ({ text }) => {
	const [isCopied, setIsCopied] = useState(false);

	const onCopyHandler = () => {
		setIsCopied(true);
		setTimeout(() => {
			setIsCopied(false);
		}, 1000);
	}

	return (
		<CopyToClipboard text={text} onCopy={onCopyHandler}>
			<span className={`${s.copyToClipboardTheWiz__copiedContainerIcon} ${isCopied ? s.copyToClipboardTheWiz__copiedContainerIcon_active : ''}`}>
				<img src={copyToClipboardLogo} alt="Copy to Clipboard" />
			</span>
		</CopyToClipboard>
	);
}

export default CopyToClipboardTheWiz;