export type PriceType = {
	id: number,
	price: number,
	maxPaymentParts: number
}

const prices: PriceType[] = [
	{
		id: 0,
		price: 0,
		maxPaymentParts: 0
	},
	{
		id: 1,
		price: 30600,
		maxPaymentParts: 9
	},
	{
		id: 2,
		price: 37800,
		maxPaymentParts: 9
	},
	{
		id: 3,
		price: 45900,
		maxPaymentParts: 9
	},
	{
		id: 4,
		price: 68850,
		maxPaymentParts: 9
	},
	{
		id: 5,
		price: 91800,
		maxPaymentParts: 9
	},
	{
		id: 6,
		price: 57600,
		maxPaymentParts: 9
	},
	{
		id: 7,
		price: 72000,
		maxPaymentParts: 9
	},
	{
		id: 8,
		price: 108000,
		maxPaymentParts: 9
	},
	{
		id: 9,
		price: 144000,
		maxPaymentParts: 9
	},
	{
		id: 10,
		price: 54000,
		maxPaymentParts: 6
	},
	{
		id: 11,
		price: 81000,
		maxPaymentParts: 9
	},
	{
		id: 12,
		price: 97200,
		maxPaymentParts: 9
	},
	{
		id: 13,
		price: 129600,
		maxPaymentParts: 9
	},
	{
		id: 14,
		price: 113400,
		maxPaymentParts: 9
	},
	{
		id: 15,
		price: 151200,
		maxPaymentParts: 9
	},
	{
		id: 16,
		price: 172800,
		maxPaymentParts: 9
	},
	{
		id: 17,
		price: 192600,
		maxPaymentParts: 9
	},
	{
		id: 18,
		price: 205200,
		maxPaymentParts: 9
	},
	{
		id: 19,
		price: 203310,
		maxPaymentParts: 9
	},
	{
		id: 20,
		price: 237600,
		maxPaymentParts: 9
	},
	{
		id: 21,
		price: 214020,
		maxPaymentParts: 9
	},
	{
		id: 22,
		price: 250200,
		maxPaymentParts: 9
	},
	{
		id: 23,
		price: 48600,
		maxPaymentParts: 9
	},
	{
		id: 24,
		price: 60750,
		maxPaymentParts: 9
	},
	{
		id: 25,
		price: 91125,
		maxPaymentParts: 9
	},
	{
		id: 26,
		price: 9000,
		maxPaymentParts: 9
	},
	{
		id: 27,
		price: 45000,
		maxPaymentParts: 9
	},
	{
		id: 28,
		price: 38700,
		maxPaymentParts: 9
	},
	{
		id: 29,
		price: 8000,
		maxPaymentParts: 1
	},
	{
		id: 30,
		price: 1000,
		maxPaymentParts: 1
	},
	{
		id: 31,
		price: 39600,
		maxPaymentParts: 9
	},
	{
		id: 32,
		price: 49500,
		maxPaymentParts: 9
	},
	{
		id: 33,
		price: 74250,
		maxPaymentParts: 9
	},
	{
		id: 34,
		price: 99000,
		maxPaymentParts: 9
	},
	{
		id: 35,
		price: 57375,
		maxPaymentParts: 9
	},
	{
		id: 36,
		price: 76500,
		maxPaymentParts: 9
	},
	{
		id: 37,
		price: 38250,
		maxPaymentParts: 9
	},
	{
		id: 38,
		price: 241200,
		maxPaymentParts: 9
	},
	{
		id: 39,
		price: 228600,
		maxPaymentParts: 9
	},
	{
		id: 40,
		price: 205020,
		maxPaymentParts: 9
	},
	{
		id: 41,
		price: 194310,
		maxPaymentParts: 9
	},
	{
		id: 42,
		price: 64800,
		maxPaymentParts: 9
	},
	{
		id: 43,
		price: 121500,
		maxPaymentParts: 9
	},
	{
		id: 44,
		price: 35100,
		maxPaymentParts: 9
	},
	{
		id: 45,
		price: 44100,
		maxPaymentParts: 9
	},
	{
		id: 46,
		price: 72900,
		maxPaymentParts: 9
	},
	{
		id: 47,
		price: 96300,
		maxPaymentParts: 9
	},
	{
		id: 48,
		price: 71100,
		maxPaymentParts: 9
	},
	{
		id: 49,
		price: 289800,
		maxPaymentParts: 9
	},
	{
		id: 50,
		price: 300600,
		maxPaymentParts: 9
	},
	{
		id: 51,
		price: 86400,
		maxPaymentParts: 9
	},
	{
		id: 52,
		price: 62100,
		maxPaymentParts: 9
	},
	{
		id: 53,
		price: 246330,
		maxPaymentParts: 9
	},
	{
		id: 54,
		price: 255510,
		maxPaymentParts: 9
	},
	{
		id: 55,
		price: 12000,
		maxPaymentParts: 1
	},
	{
		id: 56,
		price: 23400,
		maxPaymentParts: 1
	},
	{
		id: 57,
		price: 34200,
		maxPaymentParts: 1
	},
	{
		id: 58,
		price: 44400,
		maxPaymentParts: 1
	}
]

export default prices;